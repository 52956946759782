<template>
  <Layout>
    <div
      class="page-title-box d-flex align-items-center justify-content-between"
    >
      <h4 class="mb-0">Edit Member</h4>
      <span @click="$router.go(-1)" style="cursor: pointer; color: grey"
        >Back</span
      >
    </div>
    <div class="row" id="notification">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div
              class="page-title-box d-flex align-items-center justify-content-between"
            >
              <h4 class="mb-0">Edit Basic Details</h4>
            </div>
            <b-form
              enctype="multipart/form-data"
              ref="forum"
              @submit.prevent="
                this.$route.name == 'edit-member' ? 'Save Data' : 'Update Data'
              "
            >
              <div class="row">
                <b-form-group class="col-6">
                  <label for="input-2">First Name </label>
                  <b-form-input
                    id="input-2"
                    v-model="form1.fname"
                    placeholder="Enter First Name"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-6">
                  <label for="input-2">Last Name </label>
                  <b-form-input
                    id="input-2"
                    v-model="form1.lname"
                    placeholder="Enter Last Name"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-6">
                  <label for="session-date-time">Date Of Birth </label>
                  <b-form-input
                    id="session-date-time"
                    placeholder="Enter Date Of Birth"
                    v-model="form1.dob"
                    type="date"
                    :min="disabledDates()"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group class="col-6">
                  <label for="type">Speciality </label>
                  <multiselect
                    @search-change="fetchSpeciality"
                    id="input-multi"
                    v-model="form1.speciality_id"
                    :options="speciality"
                    :multiple="false"
                    track-by="id"
                    placeholder="Type here to search"
                    label="title"
                  >
                    <span slot="noOptions">Type here to search</span>
                  </multiselect>
                </b-form-group>
                <b-form-group class="col-6">
                  <label for="prof_headline">Professional Headline </label>
                  <b-form-input
                    id="prof_headline"
                    v-model="form1.professional_headline"
                    placeholder="Enter Professional Headline"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-6">
                  <label for="input-2">Experience </label>
                  <b-form-input
                    id="input-2"
                    v-model="form1.years_of_experience"
                    placeholder="Enter years of experience"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-6">
                  <label for="prof_bio">Professional Bio</label>
                  <b-form-textarea
                    id="prof_bio"
                    v-model="form1.professional_bio"
                    placeholder="Enter Professional Bio"
                    rows="3"
                  >
                  </b-form-textarea>
                </b-form-group>
                <b-form-group
                  class="col-6"
                  label="Interest Areas"
                  label-for="interest_area_div"
                  id="member-interest-area"
                >
                  <multiselect
                    @search-change="fetchInterestArea"
                    id="interest_area_div"
                    v-model="form1.interest_areas"
                    :options="interest"
                    :multiple="true"
                    track-by="id"
                    label="title"
                    placeholder="Type here to search"
                  >
                    <span slot="noOptions"> Type here to search </span>
                  </multiselect>
                </b-form-group>
                <b-form-group class="col-6">
                  <label for="input-2">Email </label>
                  <b-form-input
                    id="input-2"
                    v-model="form1.email"
                    placeholder="Enter Email"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-6">
                  <label for="input-2">Pincode </label>
                  <b-form-input
                    id="input-2"
                    v-model="form1.pincode"
                    placeholder="Enter Pincode"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-6">
                  <label for="input-2">Address </label>
                  <b-form-input
                    id="input-2"
                    v-model="form1.address"
                    placeholder="Enter Address"
                  ></b-form-input>
                </b-form-group>
                <!-- <b-form-group class="col-6">
                    <label for="type">Country <span style="color: red;">*</span></label>
                    <b-form-select @input="fetchAllCountry" id="country" v-model="form1.country" value-field="name"
                      text-field="name" :options="allCountry">
                    </b-form-select>
                  </b-form-group> -->
                <b-form-group
                  label-for="input-country"
                  label="Country"
                  class="col-6"
                >
                  <!-- <b-form-select id="input-country" v-model="form1.country" :options="allCountry" value-field="name"
                      text-field="name" @change="resetStateAndCity">
                    </b-form-select> -->
                  <multiselect
                    @search-change="fetchCountry"
                    id="interest_area_div"
                    v-model="form1.country"
                    :options="allCountry"
                    :multiple="false"
                    track-by="id"
                    label="name"
                    placeholder="Type here to search"
                    @input="resetStateAndCity"
                  >
                    <span slot="noOptions"> Type here to search </span>
                  </multiselect>
                </b-form-group>
                <b-form-group
                  label-for="input-grp-state2"
                  label="State"
                  class="col-6"
                >
                  <!-- <b-form-select id="input-grp-state2" v-model="form1.state" :options="allState" value-field="state"
                      text-field="state" @change="resetCity">
                    </b-form-select> -->
                  <multiselect
                    @search-change="getStatesByCountry"
                    id="interest_area_div"
                    v-model="form1.state"
                    :options="allState"
                    :multiple="false"
                    track-by="state"
                    label="state"
                    placeholder="Type here to search"
                    @input="resetCity"
                  >
                    <span slot="noOptions"> Type here to search </span>
                  </multiselect>
                </b-form-group>
                <b-form-group
                  label-for="input-grp-city2"
                  label="City"
                  class="col-6"
                >
                  <!-- <b-form-select id="input-grp-city2" v-model="form1.city" :options="allCity" value-field="city"
                      text-field="city">
                    </b-form-select> -->
                  <multiselect
                    @search-change="getCitiesByState"
                    id="interest_area_div"
                    v-model="form1.city"
                    :options="allCity"
                    :multiple="false"
                    track-by="city"
                    label="city"
                    placeholder="Type here to search"
                  >
                    <span slot="noOptions"> Type here to search </span>
                  </multiselect>
                </b-form-group>
                <b-form-group
                  label="Thumbnail Image [Upload Max File Size : 2MB]"
                  label-for="profile_image"
                  class="col-6"
                >
                  <b-form-file
                    id="profile_image"
                    accept="image/png, image/jpeg, image/jpg"
                    placeholder="Choose a file or drop it here..."
                    @change="readFile($event, 'profile_image')"
                    ref="profile_image"
                  >
                  </b-form-file>
                  <template
                    v-if="
                      $route.name == 'edit-member' && form1.profile_image_url
                    "
                  >
                    <img
                      :src="form1.profile_image_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                      class="edit-btn"
                    />
                  </template>
                </b-form-group>
                <b-form-group
                  label="Cover Image [Upload Max File Size : 2MB]"
                  label-for="cover_image"
                  class="col-6"
                >
                  <b-form-file
                    id="cover_image"
                    accept="image/png, image/jpeg, image/jpg"
                    placeholder="Choose a file or drop it here..."
                    @change="readFile($event, 'cover_image')"
                    ref="cover_image"
                  >
                  </b-form-file>
                  <template
                    v-if="$route.name == 'edit-member' && form1.cover_image_url"
                  >
                    <img
                      :src="form1.cover_image_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                </b-form-group>
                <b-form-group class="col-6">
                  <label for="input-multi">Member Type</label>
                  <b-form-select
                    v-model="form1.member_type"
                    :options="member_type_options"
                    value-field="value"
                    text-field="text"
                  >
                  </b-form-select>
                </b-form-group>
                <b-form-group class="col-6"
                  ><label for="input-multi" class="mr-2"
                    >Member Verified Status: </label
                  ><span
                    class="badge badge-danger p-1"
                    v-if="form1.is_verified_with_mci == '0'"
                  >
                    No Status</span
                  >
                  <span
                    class="badge badge-success p-1"
                    v-else-if="form1.is_verified_with_mci == '1'"
                  >
                    Verified</span
                  >
                  <span
                    class="badge badge-warning p-1"
                    v-if="form1.is_verified_with_mci == '2'"
                  >
                    Not Verified</span
                  >
                  <span
                    class="badge badge-primary p-1"
                    v-if="
                      form1.is_verified_with_mci == '3' ||
                      form1.is_verified_with_mci == '4'
                    "
                  >
                    Processing</span
                  >
                  <span
                    class="badge badge-info p-1"
                    v-if="form1.is_verified_with_mci == '5'"
                  >
                    Not Valid User</span
                  ><span
                    v-if="form1.is_verified_with_mci == '1'"
                    style="color: green; font-weight: bold"
                  >
                    *Verified Doctor: Registration Number Is
                    {{ form1.mci_registration_no }}</span
                  ><span
                    v-else-if="form1.is_verified_with_mci == '0'"
                    style="color: red"
                  >
                    * If you want to status change go to mci verification.</span
                  ><span v-else style="color: red">
                    *If you want to status change go to mci verification,
                    Registration number is {{ form1.mci_registration_no }}</span
                  ></b-form-group
                >
                <b-form-group class="col-6"
                  ><label for="input-multi">Whatsapp Status : </label>
                  <b-form-select
                    v-model="form1.whatsapp_status"
                    :options="whatsapp_options"
                    value-field="value"
                    text-field="text"
                  >
                  </b-form-select>
                </b-form-group>
                <b-form-group class="col-6"
                  ><label for="input-multi">Sms Status : </label>
                  <b-form-select
                    v-model="form1.sms_status"
                    :options="sms_options"
                    value-field="value"
                    text-field="text"
                  >
                  </b-form-select>
                </b-form-group>
                <b-form-group class="col-6">
                  <label for="input-2">Mci Registration No. </label>
                  <b-form-input
                    id="input-2"
                    v-model="form1.mci_registration_no"
                    placeholder="Enter mci registration number"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-for="input-grp-state2"
                  label="Mci Registration State/ Council"
                  class="col-6"
                >
                  <multiselect
                    id="interest_area_div"
                    v-model="form1.mci_registration_state"
                    :options="mciState"
                    :multiple="false"
                    track-by="state"
                    label="state"
                    placeholder="Type here to search"
                  >
                    <span slot="noOptions"> Type here to search </span>
                  </multiselect>
                </b-form-group>

                <b-form-group
                  id="input-group-1"
                  label-for="input-1"
                  class="col-6"
                >
                  <label style="font-weight: 500">Mci Registration Year </label>
                  <date-picker
                    placeholder="Enter Mci Registration Year"
                    v-model="form1.mci_registration_year"
                    type="year"
                    lang="en"
                    confirm
                  ></date-picker>
                </b-form-group>

                <b-form-group class="col-6">
                  <label for="input-2">Aleternate No. </label>
                  <b-form-input
                    id="input-2"
                    v-model="form1.alternate_number"
                    placeholder="Enter alternate number"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-6">
                  <label for="input-2">Whatsapp No. </label>
                  <b-form-input
                    id="input-2"
                    v-model="form1.whatsapp_number"
                    placeholder="Enter whatsapp number"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-6">
                  <label for="input-2">Meta Title </label>
                  <b-form-input
                    id="input-2"
                    v-model="form1.meta_title"
                    placeholder="Enter meta title"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-6">
                  <label for="input-2">Meta Description </label>
                  <b-form-input
                    id="input-2"
                    v-model="form1.meta_description"
                    placeholder="Enter meta description"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-6">
                  <label for="input-2">Meta Keyword </label>
                  <b-form-input
                    id="input-2"
                    v-model="form1.meta_keywords"
                    placeholder="Enter meta keyword"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-6">
                  <label for="input-2"> Website Link </label>
                  <b-form-input
                    id="input-2"
                    v-model="form1.website_link"
                    placeholder="Enter Link"
                    @keydown="restrictCharsForLink"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label-for="color"
                  class="col-lg-6 col-md-6 col-sm-12"
                >
                  <label>Theme Color</label>
                  <b-form-input
                    type="color"
                    id="color"
                    v-model="form1.theme_color"
                  />
                </b-form-group>
                <div class="col-12">
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mr-2"
                    @click.prevent="submitData"
                    style="margin-bottom: 20px"
                    >Save Data</b-button
                  >
                </div>
                <!-- <b-form-group class="col-12"> -->
                <div class="col-12">
                  <label for="input-multi">Member Last Active Date: </label
                  ><span class="badge badge-info p-1 ml-1">
                    {{ form1.last_active_date }}</span
                  >
                </div>
                <div
                  v-b-modal="'noteModal'"
                  class="col-12 mb-4"
                  @click="sendInfoNote(mainid)"
                >
                  <label class="mr-2">For adding Notes</label>
                  <i
                    class="mdi mdi-note-multiple"
                    style="color: #1cbb8c; font-size: 20px"
                  ></i>
                </div>
                <div class="col-12 px-0">
                  <div class="member_short_link">
                    <div class="p-2 child" style="border: 2px solid #eff2f7">
                      <label class="mr-2 mb-0">Short Link: </label>
                      <span
                        v-if="form1.short_url"
                        class="badge badge-success p-2"
                        style="white-space: normal"
                      >
                        {{ form1.short_url }}
                      </span>
                      <span
                        class="badge badge-success edit-btn p-2"
                        v-else
                        @click="shortlinkgenarateModel($route.params.id)"
                      >
                        Generate
                      </span>
                    </div>
                    <div class="p-2 child" style="border: 2px solid #eff2f7">
                      <label class="mr-2 mb-0">Social Link: </label>
                      <router-link
                        :to="{
                          name: 'member-social-links',
                          params: { id: $route.params.id },
                        }"
                        class="badge badge-primary p-2"
                      >
                        Laguages and Social Links
                      </router-link>
                    </div>
                    <div class="p-2 child" style="border: 2px solid #eff2f7">
                      <label class="mr-2 mb-0">FAQ: </label>
                      <router-link
                        :to="{
                          name: 'memebr-faq',
                          params: { lead_id: form1.lead_id },
                        }"
                        class="badge badge-secondary p-2"
                        v-if="form1.lead_id"
                        >View
                      </router-link>
                      <b-button
                        :disabled="true"
                        v-else
                        class="badge badge-secondary p-2"
                      >
                        View
                      </b-button>
                    </div>
                    <div class="p-2 child" style="border: 2px solid #eff2f7">
                      <label class="mr-2 mb-0">Email Status: </label>
                      <span
                        class="badge badge-success p-2"
                        v-if="form1.unsubscribe == '0'"
                        >Subscribe</span
                      >
                      <span class="badge badge-danger p-2" v-else
                        >Unsubscribe</span
                      >
                    </div>
                    <div
                      class="p-2 child"
                      style="border: 2px solid #eff2f7"
                      v-if="form1.whatsapp_status"
                    >
                      <label class="mr-2 mb-0">Whatsapp Status: </label>
                      <span
                        class="badge badge-info p-2"
                        style="text-transform: capitalize"
                        v-if="form1.whatsapp_status != null"
                        >{{ form1.whatsapp_status }}</span
                      >
                    </div>
                    <div
                      class="p-2 child"
                      style="border: 2px solid #eff2f7"
                      v-if="form1.mobile_number"
                    >
                      <label class="mr-2 mb-0">Mobile Number: </label>
                      <div
                        class="badge badge-warning p-2"
                        style="text-transform: capitalize"
                      >
                        <span v-if="form1.country_code"
                          >+{{ form1.country_code }}-</span
                        >
                        <span v-if="form1.mobile_number">{{
                          form1.mobile_number
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- </b-form-group> -->
              </div>
            </b-form>
            <div class="row mt-4">
              <div class="col-12">
                <div
                  class="page-title-box d-flex align-items-center justify-content-between"
                >
                  <h4 class="mb-0">workplace Details</h4>
                  <span
                    class="edit-btn badge badge-info p-2"
                    @click="addWorkPlace"
                    ><i class="fas fa-pen mr-2"></i
                    ><span>Add Workplace</span></span
                  >
                </div>
                <div
                  class="table-responsive mb-4"
                  v-if="doctor_work_place && doctor_work_place?.length > 0"
                >
                  <b-table
                    striped
                    bordered
                    :items="doctor_work_place"
                    :fields="doctor_workplace_fields"
                    :sort-by.sync="sortBy"
                    :per-page="0"
                    :current-page="currentPage"
                    :sort-desc.sync="sortDesc"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    responsive="sm"
                    class="table-bordered table-hover"
                    :key="key"
                  >
                    <template v-slot:cell(delete)="row">
                      <div @click.prevent="deleteWorkPlace(row.item.id)">
                        <i class="mdi mdi-delete delete-i"></i>
                      </div>
                    </template>
                    <template v-slot:cell(is_active)="row">
                      <b-form-checkbox
                        switch
                        class="mb-1"
                        @change="updateStatusWorkPlace(row.item.id)"
                        :checked="row.item.is_active == 1 ? true : false"
                      >
                      </b-form-checkbox
                    ></template>
                    <template v-slot:cell(edit)="row">
                      <i
                        class="fa fa-edit edit-i"
                        style="color: #5664d2; cursor: pointer"
                        @click="editWorkPlace(row.index)"
                      ></i>
                    </template>
                    <template v-slot:cell(address)="row">
                      <span class="handle-width" v-if="row.item.address">
                        {{ row.item.address }}
                      </span>
                      <span v-else>---</span>
                    </template>
                    <template v-slot:cell(currently_working)="row">
                      <img
                        src="/tick.svg"
                        height="20px"
                        style="color: red"
                        v-if="
                          row.item.currently_working != null &&
                          row.item.currently_working != ''
                        "
                      />
                      <img
                        src="/close.svg"
                        height="20px"
                        style="color: red"
                        v-else
                      />
                    </template>
                  </b-table>
                </div>
                <div class="no_content py-4" v-else>
                  <div style="display: grid; justify-items: center">
                    <img src="/noData.svg" alt="" />
                    <span class="no_content_text">No Data Available</span>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div
                  class="page-title-box d-flex align-items-center justify-content-between"
                >
                  <h4 class="mb-0">Academics Details</h4>
                  <span
                    class="edit-btn badge badge-info p-2"
                    @click="addAcademics"
                    ><i class="fas fa-pen mr-2"></i
                    ><span>Add Academic</span></span
                  >
                </div>
                <div
                  class="table-responsive mb-4"
                  v-if="doctor_academics && doctor_academics?.length > 0"
                >
                  <b-table
                    striped
                    bordered
                    :items="doctor_academics"
                    :fields="doctor_academics_fields"
                    :sort-by.sync="sortBy"
                    :per-page="0"
                    :current-page="currentPage"
                    :sort-desc.sync="sortDesc"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    responsive="sm"
                    class="table-bordered table-hover"
                    :key="key"
                  >
                    <template v-slot:cell(delete)="row">
                      <div @click.prevent="deleteAcademics(row.item.id)">
                        <i class="mdi mdi-delete delete-i"></i>
                      </div>
                    </template>
                    <template v-slot:cell(is_active)="row">
                      <b-form-checkbox
                        switch
                        class="mb-1"
                        @change="updateStatusAcademics(row.item.id)"
                        :checked="row.item.is_active == 1 ? true : false"
                      >
                      </b-form-checkbox
                    ></template>
                    <template v-slot:cell(qualification)="row">
                      <span class="doctor_detail" v-if="row.item.qualification">
                        {{ row.item.qualification }}
                      </span>
                      <span v-else>---</span>
                    </template>
                    <template v-slot:cell(course_name)="row">
                      <span class="doctor_detail" v-if="row.item.course_name">
                        {{ row.item.course_name }}
                      </span>
                      <span v-else>---</span>
                    </template>
                    <template v-slot:cell(institute_name)="row">
                      <span
                        class="doctor_detail"
                        v-if="row.item.institute_name"
                      >
                        {{ row.item.institute_name }}
                      </span>
                      <span v-else>---</span>
                    </template>
                    <template v-slot:cell(other_institute_name)="row">
                      <span
                        class="doctor_detail"
                        v-if="row.item.other_institute_name"
                      >
                        {{ row.item.other_institute_name }}
                      </span>
                      <span v-else>---</span>
                    </template>
                    <template v-slot:cell(other_course_name)="row">
                      <span
                        class="doctor_detail"
                        v-if="row.item.other_course_name"
                      >
                        {{ row.item.other_course_name }}
                      </span>
                      <span v-else>---</span>
                    </template>
                    <template v-slot:cell(formate_start_date)="row">
                      <span
                        class="doctor_detail"
                        v-if="row.item.formate_start_date"
                      >
                        {{ row.item.formate_start_date }}
                      </span>
                      <span v-else>---</span>
                    </template>
                    <template v-slot:cell(formate_end_date)="row">
                      <span
                        class="doctor_detail"
                        v-if="row.item.formate_end_date"
                      >
                        {{ row.item.formate_end_date }}
                      </span>
                      <span v-else>---</span>
                    </template>
                    <template v-slot:cell(edit)="row">
                      <i
                        class="fa fa-edit edit-i"
                        style="color: #5664d2; cursor: pointer"
                        @click="editAcademics(row.index)"
                      ></i>
                    </template>
                  </b-table>
                </div>
                <div class="no_content py-4" v-else>
                  <div style="display: grid; justify-items: center">
                    <img src="/noData.svg" alt="" />
                    <span class="no_content_text">No Data Available</span>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div
                  class="page-title-box d-flex align-items-center justify-content-between"
                >
                  <h4 class="mb-0">Publication Details</h4>
                  <span
                    class="edit-btn badge badge-info p-2"
                    @click="addPublication"
                    ><i class="fas fa-pen mr-2"></i
                    ><span>Add Publication</span></span
                  >
                </div>
                <div
                  class="table-responsive mb-4"
                  v-if="doctor_publications && doctor_publications?.length > 0"
                >
                  <b-table
                    striped
                    bordered
                    :items="doctor_publications"
                    :fields="doctor_publications_fields"
                    :sort-by.sync="sortBy"
                    :per-page="0"
                    :current-page="currentPage"
                    :sort-desc.sync="sortDesc"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    responsive="sm"
                    class="table-bordered table-hover"
                    :key="key"
                  >
                    <template v-slot:cell(edit)="row">
                      <i
                        class="fa fa-edit edit-i"
                        style="color: #5664d2; cursor: pointer"
                        @click="editPublication(row.index)"
                      ></i>
                    </template>
                    <template v-slot:cell(delete)="row">
                      <div @click.prevent="deletePublication(row.item.id)">
                        <i class="mdi mdi-delete delete-i"></i>
                      </div>
                    </template>
                    <template v-slot:cell(is_active)="row">
                      <b-form-checkbox
                        switch
                        class="mb-1"
                        @change="updateStatusPublication(row.item.id)"
                        :checked="row.item.is_active == 1 ? true : false"
                      >
                      </b-form-checkbox
                    ></template>
                  </b-table>
                </div>
                <div class="no_content py-4" v-else>
                  <div style="display: grid; justify-items: center">
                    <img src="/noData.svg" alt="" />
                    <span class="no_content_text">No Data Available</span>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div
                  class="page-title-box d-flex align-items-center justify-content-between"
                >
                  <h4 class="mb-0">Link Details</h4>
                  <span class="edit-btn badge badge-info p-2" @click="addLink"
                    ><i class="fas fa-pen mr-2"></i><span>Add Link</span></span
                  >
                </div>
                <div
                  class="table-responsive mb-4"
                  v-if="doctor_links && doctor_links?.length > 0"
                >
                  <b-table
                    striped
                    bordered
                    :items="doctor_links"
                    :fields="doctor_links_fields"
                    :sort-by.sync="sortBy"
                    :per-page="0"
                    :current-page="currentPage"
                    :sort-desc.sync="sortDesc"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    responsive="sm"
                    class="table-bordered table-hover"
                    :key="key"
                  >
                    <template v-slot:cell(edit)="row">
                      <i
                        class="fa fa-edit edit-i"
                        style="color: #5664d2; cursor: pointer"
                        @click="editLink(row.index)"
                      ></i>
                    </template>
                    <template v-slot:cell(delete)="row">
                      <i
                        class="mdi mdi-delete delete-i"
                        @click="deleteLink(row.item.id)"
                      ></i>
                    </template>
                    <template v-slot:cell(post_url)="row">
                      <p>
                        {{
                          row.item.internal_link == 1
                            ? row.item.meta_title
                            : row.item.url
                        }}
                      </p>
                    </template>
                    <template v-slot:cell(internal_link)="row">
                      <p>
                        {{ row.item.internal_link ? "Yes" : "No" }}
                      </p>
                    </template>
                  </b-table>
                </div>
                <div class="no_content py-4" v-else>
                  <div style="display: grid; justify-items: center">
                    <img src="/noData.svg" alt="" />
                    <span class="no_content_text">No Data Available</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div
                class="page-title-box d-flex align-items-center justify-content-between"
              >
                <h4 class="mb-0">Awards & Achievements</h4>
                <span
                  class="edit-btn badge badge-info p-2"
                  @click="addAwardsAchievements"
                  ><i class="fas fa-pen mr-2"></i
                  ><span>Add Achievements</span></span
                >
              </div>
              <div
                class="table-responsive mb-4"
                v-if="
                  doctor_awardsAchievemets &&
                  doctor_awardsAchievemets?.length > 0
                "
              >
                <b-table
                  striped
                  bordered
                  :items="doctor_awardsAchievemets"
                  :fields="doctor_awards_fileds"
                  :sort-by.sync="sortBy"
                  :per-page="0"
                  :current-page="currentPage"
                  :sort-desc.sync="sortDesc"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  responsive="sm"
                  class="table-bordered table-hover"
                  :key="key"
                >
                  <template v-slot:cell(edit)="row">
                    <i
                      class="fa fa-edit edit-i"
                      style="color: #5664d2; cursor: pointer"
                      @click="editAwardAchievements(row.item.id)"
                    ></i>
                  </template>
                  <template v-slot:cell(delete)="row">
                    <div @click.prevent="deleteAwardAchievements(row.item.id)">
                      <i class="mdi mdi-delete delete-i"></i>
                    </div>
                  </template>
                  <template v-slot:cell(is_active)="row">
                    <b-form-checkbox
                      switch
                      class="mb-1"
                      @change="updateStatusAwardAchievements(row.item.id)"
                      :checked="row.item.is_active == 1 ? true : false"
                    >
                    </b-form-checkbox
                  ></template>
                </b-table>
              </div>
              <div class="no_content py-4" v-else>
                <div style="display: grid; justify-items: center">
                  <img src="/noData.svg" alt="" />
                  <span class="no_content_text">No Data Available</span>
                </div>
              </div>
            </div>
          </div>
          <b-modal
            id="bvModal"
            ref="modal"
            title="Awards & Achievements"
            @ok="handleAwardAchievements"
            @cancel="closeModal"
            v-model="awardModel"
            no-close-on-backdrop
            centered
            hide-header-close
            :ok-title="is_add_award ? 'Add' : 'Edit'"
            cancel-title="Close"
            size="lg"
          >
            <form ref="form" @submit.stop.prevent="handleAwardAchievements">
              <b-form-group label-for="name-input">
                <b-form-group>
                  <label for="input-2"
                    >Name <span style="color: red">*</span></label
                  >
                  <b-form-input
                    id="name"
                    v-model="awardsForm.name"
                    placeholder="Enter name"
                  ></b-form-input>
                </b-form-group>
              </b-form-group>
            </form>
          </b-modal>
          <b-modal
            id="bvModal"
            ref="modal"
            title="Workplace Details"
            @ok="handleWorkplaces"
            @cancel="closeModal"
            v-model="is_work_place_modal"
            no-close-on-backdrop
            centered
            hide-header-close
            :ok-title="is_add_workplace ? 'Add' : 'Edit'"
            cancel-title="Close"
            size="xl"
          >
            <form ref="form" @submit.stop.prevent="handleSubmitWorkplaces">
              <div label-for="name-input" class="row">
                <b-form-group class="col-6">
                  <label for="input-2"
                    >Designation <span style="color: red">*</span></label
                  >
                  <b-form-input
                    id="input-2"
                    v-model="form2.designation"
                    placeholder="Enter Designation"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-6">
                  <label for="input-2"
                    >Place of work <span style="color: red">*</span></label
                  >
                  <b-form-input
                    id="input-2"
                    v-model="form2.place_of_work"
                    placeholder="Enter Place of work"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-for="work_place_type" class="col-6">
                  <label for="input-2"
                    >Type of Workplace <span style="color: red">*</span></label
                  >
                  <b-form-select
                    id="work_place_type"
                    v-model="form2.work_place_type"
                    :options="workplacelist"
                    value-field="name"
                    text-field="name"
                  >
                  </b-form-select>
                </b-form-group>
                <b-form-group label-for="country" class="col-6">
                  <label for="input-2"
                    >Country <span style="color: red">*</span></label
                  >
                  <multiselect
                    @search-change="fetchCountry"
                    id="interest_area_div"
                    v-model="form2.country"
                    :options="allCountry"
                    :multiple="false"
                    track-by="name"
                    label="name"
                    placeholder="Type here to search"
                    @select="resetWorkPlaceStateAndCity"
                  >
                    <span slot="noOptions"> Type here to search </span>
                  </multiselect>
                </b-form-group>
                <b-form-group label-for="state2" class="col-6">
                  <label for="input-2"
                    >State <span style="color: red">*</span></label
                  >
                  <multiselect
                    @search-change="getStateWorkPlacesByCountry"
                    id="interest_area_div"
                    v-model="form2.state"
                    :options="allWorkPlaceState"
                    :multiple="false"
                    track-by="state"
                    label="state"
                    placeholder="Type here to search"
                    @select="resetWorkPlaceCity"
                  >
                    <span slot="noOptions"> Type here to search </span>
                  </multiselect>
                </b-form-group>
                <b-form-group label-for="city2" class="col-6">
                  <label for="input-2"
                    >City <span style="color: red">*</span></label
                  >
                  <multiselect
                    @search-change="getWorkPlaceCitiesByState"
                    id="interest_area_div"
                    v-model="form2.city"
                    :options="allWorkPlaceCity"
                    :multiple="false"
                    track-by="city"
                    label="city"
                    placeholder="Type here to search"
                  >
                    <span slot="noOptions"> Type here to search </span>
                  </multiselect>
                </b-form-group>
                <b-form-group class="col-6">
                  <label for="start-date">Start Date </label>
                  <b-form-input
                    id="start-date"
                    placeholder="Enter Start Date"
                    v-model="form2.start_date"
                    type="date"
                    :min="disabledDates()"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  v-if="form2.currently_working == '0'"
                  class="col-6"
                >
                  <label for="end-date">End Date </label>
                  <b-form-input
                    id="end-date"
                    placeholder="Enter End Date"
                    v-model="form2.end_date"
                    type="date"
                    :min="disabledDates()"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group class="col-12">
                  <label for="input-2">Address </label>
                  <b-form-input
                    id="input-2"
                    v-model="form2.address"
                    placeholder="Enter Address"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-12"
                  style="display: flex; align-items: center"
                >
                  <div style="display: flex; gap: 10px">
                    <b-form-checkbox
                      v-model="form2.currently_working"
                      value="1"
                      unchecked-value="0"
                      >Currently Working</b-form-checkbox
                    >
                  </div>
                </b-form-group>
              </div>
            </form>
          </b-modal>
          <b-modal
            id="bvModal"
            ref="modal"
            title="Academics Details"
            @ok="handleAcademics"
            @cancel="closeModal"
            v-model="is_academic_modal"
            no-close-on-backdrop
            centered
            hide-header-close
            :ok-title="is_add_academic ? 'Add' : 'Edit'"
            cancel-title="Close"
            size="lg"
          >
            <form ref="form" @submit.stop.prevent="handleSubmitAcademics">
              <b-form-group label-for="name-input" class="">
                <b-form-group label-for="qualification">
                  <label for="input-2"
                    >Qualification <span style="color: red">*</span></label
                  >
                  <multiselect
                    @search-change="fetchQualification"
                    id="name-input"
                    v-model="form4.course_name"
                    :options="qualification"
                    :multiple="false"
                    track-by="course_name"
                    label="course_name"
                    placeholder="Type here to search"
                    @input="updateQulificationRefNo"
                  >
                    <span slot="noOptions"> Type here to search </span>
                  </multiselect>
                </b-form-group>
                <div v-if="form4.course_name.course_name == 'Others'">
                  <b-form-group>
                    <label for="input-2"
                      >Other Qualification
                      <span style="color: red">*</span></label
                    >
                    <b-form-input
                      id="input-2"
                      v-model="form4.other_course_name"
                      placeholder="Enter Course Name"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <b-form-group label-for="institute">
                  <!-- <b-form-select id="institute" v-model="form4.institute_name" :options="institute" value-field="institute_name"
                                  text-field="institute_name" @change="updateInstituteRefNo">
                    </b-form-select> -->
                  <label for="input-2"
                    >Institue Name <span style="color: red">*</span></label
                  >
                  <multiselect
                    id="institute"
                    @search-change="fetchInstitute"
                    v-model="form4.institute_name"
                    :options="institute"
                    :multiple="false"
                    track-by="institute_name"
                    label="institute_name"
                    placeholder="Type here to search"
                    @input="updateInstituteRefNo"
                  >
                    <span slot="noOptions"> Type here to search </span>
                  </multiselect>
                </b-form-group>

                <div v-if="form4.institute_name.institute_name == 'Other'">
                  <b-form-group>
                    <label for="input-2"
                      >Other Institute Name
                      <span style="color: red">*</span></label
                    >
                    <b-form-input
                      id="input-2"
                      v-model="form4.other_institute_name"
                      placeholder="Enter Institute Name"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <b-form-group>
                  <label for="start-date">Start Date </label>
                  <b-form-input
                    id="start-date"
                    placeholder="Enter Start Date"
                    v-model="form4.start_date"
                    type="month"
                    :min="disabledDates()"
                  >
                  </b-form-input>
                </b-form-group>
                <div v-if="form4.currently_pursuing == '0'">
                  <label for="ac-end-date1">End Date </label>
                  <b-form-input
                    id="ac-end-date1"
                    placeholder="Enter End Date"
                    v-model="form4.end_date"
                    type="month"
                    :min="disabledDates()"
                  >
                  </b-form-input>
                </div>
                <div
                  class="col-md-6 pl-0 pt-2"
                  style="display: flex; align-items: start"
                >
                  <div style="display: flex; gap: 10px">
                    <b-form-checkbox
                      v-model="form4.currently_pursuing"
                      value="1"
                      unchecked-value="0"
                      >Currently Pursuing</b-form-checkbox
                    >
                  </div>
                </div>
              </b-form-group>
            </form>
          </b-modal>
          <b-modal
            id="bvModal"
            ref="modal"
            title="Publication Details"
            @ok="handlePublications"
            @cancel="closeModal"
            v-model="is_publication_modal"
            no-close-on-backdrop
            centered
            hide-header-close
            :ok-title="is_add_publication ? 'Add' : 'Edit'"
            cancel-title="Close"
            size="lg"
          >
            <form ref="form" @submit.stop.prevent="handleSubmitPublications">
              <b-form-group label-for="name-input">
                <b-form-group>
                  <label for="input-2"
                    >Title <span style="color: red">*</span></label
                  >
                  <b-form-input
                    id="title"
                    v-model="form5.title"
                    placeholder="Enter Title"
                  ></b-form-input>
                </b-form-group>
                <b-form-group>
                  <label for="input-2"
                    >Link <span style="color: red">*</span></label
                  >
                  <b-form-input
                    id="link"
                    v-model="form5.link"
                    placeholder="Enter Place of work"
                  ></b-form-input>
                </b-form-group>
                <b-form-group>
                  <label for="publish"
                    >Published On <span style="color: red">*</span></label
                  >
                  <b-form-input
                    id="publish"
                    placeholder="Enter Publish date"
                    v-model="form5.published_on"
                    type="date"
                    :min="disabledDates()"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group>
                  <label for="summary"
                    >Summary <span style="color: red">*</span></label
                  >
                  <b-form-input
                    id="summary"
                    placeholder="Enter Summary"
                    v-model="form5.summery"
                  ></b-form-input>
                </b-form-group>
              </b-form-group>
            </form>
          </b-modal>
          <!-- Add links modal -->
          <b-modal
            id="bvModal"
            ref="modal"
            title="Link Details"
            @ok="handleLinks"
            @cancel="closeModal"
            v-model="is_link_modal"
            no-close-on-backdrop
            centered
            hide-header-close
            :ok-title="is_add_link ? 'Add' : 'Edit'"
            cancel-title="Close"
            size="xl"
          >
            <form ref="form" @submit.stop.prevent="handleSubmitLinks">
              <b-form-group>
                <label>URL Link </label>
                <div
                  style="
                    display: grid;
                    grid-template-columns: 1fr 0.3fr;
                    grid-gap: 10px;
                  "
                >
                  <b-form-input
                    type="text"
                    id="url_link"
                    placeholder="Enter URL Link"
                    @change="changePostUrl"
                    v-model="fetch_url"
                  />
                  <b-button
                    class="btn-info btn-sm"
                    style="font-size: 15px"
                    @click="fetchMetadata"
                    :disabled="!fetch_url"
                    id="fetch-url"
                    >Fetch URL</b-button
                  >
                </div>
              </b-form-group>
              <p style="text-align: center">OR</p>
              <b-form-group>
                <label>Medisage Blog Link </label>
                <div
                  style="
                    display: grid;
                    grid-template-columns: 1fr 0.3fr;
                    grid-gap: 10px;
                  "
                >
                  <multiselect
                    @search-change="fetchMedisageBlog"
                    id="input-multi"
                    v-model="post_url"
                    :options="medisageBlogList"
                    :multiple="false"
                    track-by="id"
                    placeholder="Type here to search"
                    label="post_title"
                    @select="removePostUrl"
                  >
                    <span slot="noOptions">Type here to search</span>
                  </multiselect>
                </div>
              </b-form-group>
            </form>
          </b-modal>
          <b-modal id="noteModal" size="lg">
            <h2 class="text-center py-3">Notes</h2>
            <div class="container py-2">
              <div class="row" v-for="message in messages" :key="message.id">
                <div class="col-auto text-center flex-column d-none d-sm-flex">
                  <div class="row h-50">
                    <div class="col">&nbsp;</div>
                    <div class="col">&nbsp;</div>
                  </div>
                  <h5 class="m-2">
                    <span class="badge1 badge-pill bg-light border"
                      >&nbsp;</span
                    >
                  </h5>
                  <div class="row h-50">
                    <div class="col border-right">&nbsp;</div>
                    <div class="col">&nbsp;</div>
                  </div>
                </div>
                <div class="col py-2">
                  <div class="card">
                    <div
                      class="card-body"
                      style="
                        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
                      "
                    >
                      <div class="float-right text-muted">
                        {{ getSessionStart(message.created_at) }}
                      </div>
                      <h4
                        class="card-title mb-0"
                        v-if="message.notes"
                        v-html="renderClickableLink(message.notes)"
                      ></h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <template v-slot:modal-footer class="p-0">
              <b-input-group
                @submit.prevent="sendMessage('out')"
                id="person2-form"
              >
                <label
                  for="person2-input"
                  class="mb-0 mr-2 d-flex align-items-center"
                  >You :
                </label>
                <b-input
                  v-model="message"
                  id="person2-input"
                  type="text"
                  placeholder="Type your message"
                ></b-input>
                <b-button
                  :disabled="message == ''"
                  @click="storeNotes(id, message)"
                  type="submit"
                  class="ml-2"
                  >Send</b-button
                >
              </b-input-group>
            </template>
          </b-modal>
          <b-modal
            id="bvModal"
            v-model="showShortLinkGenerateModel"
            title="Short Link"
            hide-footer
            centered
            @hidden="clearShortLinkForm"
          >
            <b-form enctype="multipart/form-data" ref="email" class="row">
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-12"
              >
                <label>URL <span style="color: red">*</span></label>
                <div
                  class="d-flex align-items-center"
                  :style="{ marginRight: '2px' }"
                >
                  <span>https://wow.doctor/</span>
                  <b-form-input
                    id="input-1"
                    placeholder="Enter url"
                    v-model="shortLinkForm.short_link"
                    @keydown="restrictSpecialChars"
                  ></b-form-input>
                </div>
                <div
                  v-if="submitted && !$v.shortLinkForm.short_link.required"
                  class="invalid-feedback"
                >
                  url is required.
                </div>
              </b-form-group>
              <div class="col-12 d-flex justify-content-end">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-2"
                  @click.prevent="generateShortLink"
                  >Generate
                </b-button>
              </div>
            </b-form>
          </b-modal>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import MixinRequest from "../../../mixins/request";
import member_mixin from "../../../mixins/ModuleJs/member-list";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { members } from "../../../js/path";
export default {
  data() {
    return {
      store: false,
      submitted: false,
      title: "Edit Member",
      message: "",
      messages: [],
    };
  },
  mixins: [MixinRequest, member_mixin],
  components: {
    Layout,
    Multiselect,
    DatePicker,
  },
  validations: {
    // form2: {
    //   designation: { required },
    //   place_of_work: { required },
    //   work_place_type: { required },
    //   country: { required },
    //   state: {required},
    //   city: {required},
    //   start_date: {required},
    //   end_date: {required},
    //   address: {required}
    // },
    form4: {
      course_name: { required },
      institute_name: { required },
      start_date: { required },
    },
    publication: {
      title: { required },
      link: { required },
      published_on: { required },
      summery: { required },
    },
    link: {
      url: { required },
    },
    awardsForm: {
      name: { required },
    },
  },
  methods: {
    disabledDates() {
      return new Date().toISOString().slice(0, 16);
    },
    sendInfoNote(val) {
      this.id = val;
      this.fetchNotes(this.id);
    },
    getSessionStart: function (session_time) {
      let dateTime1 = moment(session_time).format("LLLL");
      return dateTime1;
    },
    async fetchNotes(val) {
      const url = members.fetchNotes + val;
      const data = await this.getRequest(url);
      console.log(data);
      this.messages = data.response;
    },

    async storeNotes(val, message) {
      this.message = "";
      try {
        const url = members.storeNotes;
        let dataAppend = new FormData();
        dataAppend.append("member_id", val);
        dataAppend.append("notes", message);
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        // this.isClickedMciReferrel = false;
        this.fetchNotes(this.$route.params.id);
      } catch (err) {
        console.log(err);
      }
    },
    renderClickableLink(message) {
      const clickableText = message.replace(
        /(https?:\/\/[^\s]+)/g,
        '<a href="$1" target="_blank">$1</a>'
      );
      return clickableText;
    },
  },
};
</script>
