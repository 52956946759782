import {
  members,
  member_tag,
  speciality,
  community,
  country,
  stateUrl,
  cityUrl,
  mci_verification,
  medisage_blog,
} from "../../js/path";
import _ from "lodash";

export default {
  data() {
    return {
      fetch_url: "",
      post_url: null,
      medisageBlogList: [],
      member_data: [],
      member_fields: [
        {
          key: "short_link",
          label: "Short Link",
        },
        {
          key: "social_link",
          label: "Social Link",
        },
        {
          key: "faq",
        },
      ],
      is_add_workplace: false,
      is_add_academic: false,
      is_add_publication: false,
      is_add_link: false,
      is_add_award: false,
      mciState: [],
      mainid: this.$route.params.id,
      submitted: false,
      member_list: [],
      getMemberTag: [],
      speciality: [],
      interest: [],
      allCountry: [],
      allState: [],
      allWorkPlaceState: [],
      allCity: [],
      allWorkPlaceCity: [],
      doctor_work_place: [],
      doctor_academics: [],
      doctor_publications: [],
      doctor_awardsAchievemets: [],
      doctor_links: [],
      qualification: [],
      institute: [],
      workplacelist: [],
      member_type_options: [
        {
          text: "Doctor",
          value: "doctor",
        },
        {
          text: "Ayush Doctor",
          value: "ayush_doctor",
        },
        {
          text: "Retired not practicing doctor",
          value: "retired_not_practicing_doctor",
        },
        {
          text: "PG Student",
          value: "pg_student",
        },
        {
          text: "UG Student",
          value: "ug_student",
        },
        {
          text: "Dentist",
          value: "dentist",
        },
        {
          text: "Health Care Administrator",
          value: "health_care_administrator",
        },
        {
          text: "Nurses",
          value: "nurses",
        },
        {
          text: "Pharmacist",
          value: "pharmacist",
        },
        {
          text: "Allied Health Care Worker",
          value: "allied_health_care_worker",
        },
        {
          text: "Technician",
          value: "Technician",
        },
        {
          text: "Psychologists",
          value: "psychologists",
        },
        {
          text: "Others",
          value: "other",
        },
      ],
      whatsapp_options: [
        {
          text: "Not attempted",
          value: "not attempted",
        },
        {
          text: "Read",
          value: "read",
        },
        {
          text: "Failed",
          value: "failed",
        },
        {
          text: "Not sent",
          value: "not sent",
        },
        {
          text: "Delivered",
          value: "delivered",
        },
        {
          text: "Replied",
          value: "replied",
        },
      ],
      sms_options: [
        {
          text: "Not sent",
          value: "not sent",
        },
        {
          text: "Failed",
          value: "failed",
        },
        {
          text: "Delivered",
          value: "delivered",
        },
        {
          text: "Submitted",
          value: "submitted",
        },
        {
          text: "Clicked",
          value: "clicked",
        },
      ],
      form: {
        id: "",
        member_tag_type_id: "",
        member_name: "",
        email: "",
        mobile_number: "",
      },
      form1: {
        fname: "",
        lname: "",
        dob: "",
        speciality_id: "",
        years_of_experience: "",
        interest_areas: [],
        email: "",
        mobile_number: "",
        country_code: "",
        pincode: "",
        address: "",
        country: "",
        state: "",
        city: "",
        profile_image: "",
        profile_image_url: "",
        member_type: "",
        is_verified_with_mci: 0,
        last_active_date: "",
        mci_registration_no: "",
        mci_registration_state: "",
        mci_registration_year: "",
        whatsapp_status: "",
        sms_status: "",
        alternate_number: "",
        whatsapp_number: "",
        cover_image: "",
        cover_image_url: "",
        professional_bio: "",
        professional_headline: "",
        theme_color: "",
        website_link: "",
        meta_title: "",
        meta_description: "",
        meta_keywords: "",
        short_url: "",
        lead_id: null,
        unsubscribe: "",
      },
      profile_image: "",
      profile_image_url: "",
      cover_image: "",
      cover_image_url: "",
      form2: {
        address: "",
        city: "",
        country: "",
        currently_working: false,
        designation: "",
        end_date: "",
        formate_end_date: "",
        formate_start_date: "",
        id: "",
        member_id: "",
        member_workplace_info_ref_no: "",
        place_of_work: "",
        start_date: "",
        state: "",
        work_place_type: "",
      },
      form3: {
        password: "",
      },
      form4: {
        member_academic_info_ref_no: "",
        course_name: "",
        currently_pursuing: false,
        degree_name: "",
        end_date: "",
        formate_end_date: "",
        formate_start_date: "",
        id: "",
        institute_master_ref_no: "",
        institute_name: "",
        member_id: "",
        other_course_name: "",
        other_institute_name: "",
        qualification: "",
        qualifiation_detail_master_ref_no: "",
        start_date: "",
      },
      form5: {
        member_publication_ref_no: "",
        formate_published_on: "",
        link: "",
        published_on: "",
        summery: "",
        title: "",
      },
      form6: {
        member_id: "",
        meta_title: "",
        meta_desc: "",
        meta_image: "",
        url: "",
      },
      currentPage: 1,
      params: "&page=1",
      key: 0,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      doctor_workplace_fields: [
        {
          key: "id",
        },
        {
          key: "place_of_work",
          class: "c_touchpt_header",
          label: "Place of work",
        },
        {
          key: "work_place_type",
          class: "c_touchpt_header",
          label: "Workplace type",
        },
        {
          key: "country",
          class: "c_touchpt_header",
        },
        {
          key: "city",
          class: "c_touchpt_header",
        },
        {
          key: "state",
          class: "c_touchpt_header",
        },
        {
          key: "address",
        },
        {
          key: "designation",
          class: "c_touchpt_header",
        },
        {
          key: "currently_working",
          class: "c_touchpt_header",
          label: "Currently working",
        },
        {
          key: "is_active",
          label: "Status",
        },
        {
          key: "edit",
          class: "c_touchpt_header",
        },
        {
          key: "delete",
        },
      ],
      doctor_academics_fields: [
        {
          key: "id",
        },
        {
          key: "course_name",
          label: "Course name",
        },
        {
          key: "other_course_name",
          label: "Other course name",
        },
        {
          key: "institute_name",
          label: "Institute name",
        },
        {
          key: "other_institute_name",
          label: "Other institute name",
        },
        {
          key: "formate_start_date",
          label: "Start date",
          class: "c_touchpt_header",
        },
        {
          key: "formate_end_date",
          label: "End date",
          class: "c_touchpt_header",
        },
        {
          key: "is_active",
          label: "Status",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      doctor_publications_fields: [
        {
          key: "id",
        },
        {
          key: "title",
        },
        {
          key: "link",
        },
        {
          key: "formate_published_on",
          label: "Published on",
        },
        {
          key: "summery",
          label: "Summary",
        },
        {
          key: "is_active",
          label: "Status",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      doctor_awards_fileds: [
        {
          key: "id",
        },
        {
          key: "name",
        },
        {
          key: "is_active",
          label: "Status",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      doctor_links_fields: [
        {
          key: "id",
        },
        {
          key: "post_url",
          label: "URL",
        },
        {
          key: "internal_link",
          label: "Medisage Post",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "name",
          label: "Member Name",
          class: "c_touchpt_header",
        },
        {
          key: "speciality_name",
          label: "Speciality",
          class: "c_touchpt_header",
        },
        {
          key: "city",
          class: "c_touchpt_header",
        },
        {
          key: "email",
          class: "c_touchpt_header",
        },
        {
          key: "member_tag_type",
          label: "Member Tag Type",
          class: "c_touchpt_header",
        },
        {
          key: "tag_member",
          label: "Tag Member",
          class: "c_touchpt_header",
        },
        {
          key: "last_active_date",
          label: "Last Active Date",
          class: "c_touchpt_header",
        },
        {
          key: "edit",
        },
        {
          key: "practice",
          label: "Create Practice Page",
          class: "c_touchpt_header",
        },
        {
          key: "verified",
          label: "Verification",
          class: "c_touchpt_header",
        },
        {
          key: "mci_verify",
          label: "MCI Verify",
          class: "c_touchpt_header",
        },
        {
          key: "delete_profile",
          class: "c_touchpt_header",
        },
        {
          key: "ban_user",
          class: "c_touchpt_header",
        },
        // {
        //   key: "short_link",
        //   label: "Short Link",
        //   class: "c_touchpt_header",
        // },
        // {
        //   key: "is_verified_with_mci",
        //   label: "Member status",
        //   class: "c_touchpt_header",
        // },
        // {
        //   key: "unsubscribe",
        //   label: "Email status",
        //   class: "c_touchpt_header",
        // },
        // {
        //   key: "whatsapp_status",
        //   label: "Whatsapp status",
        //   class: "c_touchpt_header",
        // },
        {
          key: "is_review",
          label: "Is Review",
          class: "c_touchpt_header",
        },
        {
          key: "demo_number",
          label: "Internal User Status",
          class: "c_touchpt_header",
        },
        // {
        //   key: "faq",
        //   label: "FAQ",
        // },
        // {
        //   key: "social_links",
        //   class: "c_touchpt_header",
        // },
      ],
      showModal: false,
      modalItem: null,
      errorMessage: false,
      nameState: null,
      is_work_place_modal: false,
      is_academic_modal: false,
      is_publication_modal: false,
      is_link_modal: false,
      work_place: {},
      academic: {},
      publication: {},
      showPasswordModal: false,
      passwordMatch: "",
      showMciModal: false,
      verification_data: null,
      publishState: null,
      selectedMember: {},
      showShortLinkGenerateModel: false,
      shortLinkForm: {
        member_id: "",
        short_link: "",
      },
      custom_badge_icon: "",
      awardModel: false,
      awardsForm: {
        id: null,
        name: "",
      },
      is_update: false,
    };
  },
  methods: {
    removePostUrl(data) {
      console.log(data, "data on change");
      this.fetch_url = "";
      this.form6.meta_title = data?.meta_title;
      this.form6.meta_desc = data?.meta_description;
      this.form6.meta_image = data?.image;
      this.form6.url = data?.post_name;
    },
    async fetchSinglePost() {
      this.$store.commit("loader/updateStatus", true);
      if (this.post_url?.id) {
        const url = medisage_blog.getMediBlogPostUrl;
        const data = await this.getRequest(url + "/" + this.post_url?.id);
        if (data.status) {
          this.form6.id = data?.response?.id;
          this.form6.meta_desc = data?.response?.meta_description;
          this.form6.meta_title = data?.response?.meta_title;
          this.form6.meta_image = data?.response?.image;
          this.form6.url = data?.response?.post_name;
        }
      }
      this.$store.commit("loader/updateStatus", false);
    },
    rowClass(item) {
      if (item.delete_profile === 1) {
        return "table-danger";
      } else {
        return "table-light";
      }
    },
    shortlinkgenarateModel(id) {
      console.log(id);
      this.showShortLinkGenerateModel = true;
      this.shortLinkForm.member_id = id;
    },

    clearShortLinkForm() {
      this.shortLinkForm.short_link = "";
    },

    checkShortLinkValidity() {
      if (this.shortLinkForm.short_link == "") {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "URL Field Required",
        });
        return false;
      }
      const validCharactersRegex = /^[A-Za-z0-9_-]*$/;

      if (!validCharactersRegex.test(this.shortLinkForm.short_link)) {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Invalid Characters in URL",
        });
        return false;
      }
      return true;
    },

    checkWebsiteLinkValidity() {
      const validCharactersRegex = /^[a-zA-Z0-9_.:/-]*$/;

      if (!validCharactersRegex.test(this.form1.website_link)) {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Invalid characters in website link",
        });
        return false;
      }
      return true;
    },

    restrictSpecialChars(event) {
      const regex = /[a-zA-Z0-9_-]/;
      if (!regex.test(event.key)) {
        event.preventDefault();
      }
    },

    restrictCharsForLink(event) {
      const regex = /[a-zA-Z0-9_.:/-]/;
      if (!regex.test(event.key)) {
        event.preventDefault();
      }
    },

    async generateShortLink() {
      let validateForm = this.checkShortLinkValidity();
      if (!validateForm) {
        return;
      } else {
        this.$store.commit("loader/updateStatus", true);
        try {
          let url = members.generateShortLink;
          const linkdata = new FormData();
          for (var key in this.shortLinkForm) {
            linkdata.append(key, this.shortLinkForm[key]);
          }
          const data = await this.postRequest(url, linkdata);
          if (data?.status) {
            // let index = this.member_list.data.findIndex(
            //   (e) => e.id === this.shortLinkForm.member_id
            // );
            // this.member_list.data[index].short_url = data.response;
            this.showShortLinkGenerateModel = false;
            this.form1.short_url = data?.response;
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data?.message,
            });
          }
        } catch (err) {
          this.showShortLinkGenerateModel = false;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Something went wrong",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      }
    },

    getMciStatusFilter(status) {
      switch (status) {
        case 0:
          return "pending";
        case 1:
          return "verified";
        case 2:
          return "not_verified";
        case 3:
        case 4:
          return "processing";
        case 5:
        case 6:
        case 7:
          return "blockUser";
        case 8:
          return "demoAccount";
        default:
          return "";
      }
    },

    MciModal(id, m_no, is_published) {
      this.publishState = is_published;
      this.selectedMember["member_id"] = id;
      this.selectedMember["mobile_number"] = m_no;
      this.selectedMember["is_published"] = this.publishState == 1 ? 0 : 1;
      this.fetchMemberVerificationData(id);
      this.showMciModal = true;
    },

    async fetchMemberVerificationData(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = `${members.getVerication}/${id}`;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.verification_data = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async verifyMember() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = members.memebrVerification;
        const data = await this.postRequest(url, this.selectedMember);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data?.message,
          });
          this.showMciModal = false;
          this.fetchData();
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    editWorkPlace(index) {
      this.is_add_workplace = false;
      this.is_work_place_modal = true;
      const data = this.doctor_work_place[index];
      console.log(data, "data***", index);
      if (data?.address != null && data?.address != "null") {
        this.form2.address = data?.address;
      }
      if (data.start_date != null || data.start_date != "") {
        this.form2.start_date = data.start_date;
      }
      if (data.formate_start_date != null || data.formate_start_date != "") {
        this.form2.formate_start_date = data.formate_start_date;
      }
      if (data.work_place_type != null || data.work_place_type != "") {
        this.form2.work_place_type = data.work_place_type;
      }
      if (data.designation != null || data.designation != "") {
        this.form2.designation = data.designation;
      }
      if (data.place_of_work != null || data.place_of_work != "") {
        this.form2.place_of_work = data.place_of_work;
      }
      if (data.country != null && data.country != "") {
        console.log(data.country, "country");
        this.form2.country = {
          name: data.country,
        };
      }
      if (data.state != null && data.state != "") {
        console.log(data.state, "data.state.state");
        this.form2.state = {
          state: data.state,
        };
      }
      if (data.city != null && data.city != "") {
        console.log(data.city, "data.city");
        this.form2.city = {
          city: data.city,
        };
      }
      if (data.end_date != null || data.end_date != "") {
        this.form2.end_date = data.end_date;
      }
      if (data.currently_working != null || data.currently_working != "") {
        this.form2.currently_working = data.currently_working;
      }
      if (
        data.member_workplace_info_ref_no != null ||
        data.member_workplace_info_ref_no != ""
      ) {
        this.form2.member_workplace_info_ref_no =
          data.member_workplace_info_ref_no;
      }
    },
    addWorkPlace() {
      this.is_add_workplace = true;
      this.is_work_place_modal = true;
      this.form2.address = "";
      this.form2.start_date = "";
      this.form2.formate_start_date = "";
      this.form2.work_place_type = "";
      this.form2.designation = "";
      this.form2.place_of_work = "";
      this.form2.country = "";
      this.form2.state = "";
      this.form2.city = "";
      this.form2.end_date = "";
      this.form2.currently_working = 0;
      this.form2.member_workplace_info_ref_no = "";
    },
    editAcademics(index) {
      this.is_add_academic = false;
      this.is_academic_modal = true;
      const data = this.doctor_academics[index];
      console.log(data, "academic data", this.academic_key, index);
      if (
        data.member_academic_info_ref_no != null ||
        data.member_academic_info_ref_no != ""
      ) {
        this.form4.member_academic_info_ref_no =
          data.member_academic_info_ref_no;
      }
      if (data.currently_pursuing != null || data.currently_pursuing != "") {
        this.form4.currently_pursuing = data.currently_pursuing;
      }
      if (data.degree_name != null || data.degree_name != "") {
        this.form4.degree_name = data.degree_name;
      }
      if (data.end_date != null || data.end_date != "") {
        this.form4.end_date = data.end_date;
      }
      if (data.formate_end_date != null || data.formate_end_date != "") {
        this.form4.formate_end_date = data.formate_end_date;
      }
      if (data.formate_start_date != null || data.formate_start_date != "") {
        this.form4.formate_start_date = data.formate_start_date;
      }
      if (data.id != null || data.id != "") {
        this.form4.id = data.id;
      }
      if (
        data.institute_master_ref_no != null ||
        data.institute_master_ref_no != ""
      ) {
        this.form4.institute_master_ref_no = data.institute_master_ref_no;
      }
      if (data.member_id != null || data.member_id != "") {
        this.form4.member_id = data.member_id;
      }
      if (data.currently_pursuing != null || data.currently_pursuing != "") {
        this.form4.currently_pursuing = data.currently_pursuing;
      }
      if (data.course_name == "Others") {
        this.form4.course_name = {
          course_name: data.course_name,
        };
        if (data.other_course_name != null && data.other_course_name != "") {
          this.form4.other_course_name = data.other_course_name;
        }
      } else {
        if (data.course_name != null && data.course_name != "") {
          this.form4.course_name = {
            course_name: data.course_name,
          };
        }
      }
      if (data?.institute_name == "Other") {
        this.form4.institute_name = {
          institute_name: data.institute_name,
        };
        if (
          data.other_institute_name != null &&
          data.other_institute_name != ""
        ) {
          this.form4.other_institute_name = data.other_institute_name;
        }
      } else {
        if (data.institute_name != null && data.institute_name != "") {
          this.form4.institute_name = {
            institute_name: data.institute_name,
          };
        }
      }
      if (data.qualification != null || data.qualification != "") {
        this.form4.qualification = data.qualification;
      }
      if (
        data.qualifiation_detail_master_ref_no != null ||
        data.qualifiation_detail_master_ref_no != ""
      ) {
        this.form4.qualifiation_detail_master_ref_no =
          data.qualifiation_detail_master_ref_no;
      }
      if (data.start_date != null || data.start_date != "") {
        this.form4.start_date = data.start_date;
      }
    },
    addAcademics() {
      this.is_add_academic = true;
      this.is_academic_modal = true;
      this.form4.member_academic_info_ref_no = "";
      this.form4.course_name = "";
      this.form4.currently_pursuing = 0;
      this.form4.degree_name = "";
      this.form4.end_date = "";
      this.form4.formate_end_date = "";
      this.form4.formate_start_date = "";
      this.form4.id = "";
      this.form4.institute_master_ref_no = "";
      this.form4.institute_name = "";
      this.form4.member_id = "";
      this.form4.other_course_name = "";
      this.form4.other_institute_name = "";
      this.form4.qualification = "";
      this.form4.qualifiation_detail_master_ref_no = "";
      this.form4.start_date = "";
    },
    editPublication(index) {
      this.is_add_publication = false;
      this.is_publication_modal = true;
      const data = this.doctor_publications[index];
      if (
        data.member_publication_ref_no != null ||
        data.member_publication_ref_no != ""
      ) {
        this.form5.member_publication_ref_no = data.member_publication_ref_no;
      }
      if (data.title != null || data.title != "") {
        this.form5.title = data.title;
      }
      if (data.link != null || data.link != "") {
        this.form5.link = data.link;
      }
      if (data.published_on != null || data.published_on != "") {
        this.form5.published_on = data.published_on;
      }
      if (data.summery != null || data.summery != "") {
        this.form5.summery = data.summery;
      }
    },
    editAwardAchievements(id) {
      this.is_add_award = false;
      this.awardModel = true;
      const index = this.doctor_awardsAchievemets.findIndex((e) => e.id == id);
      const data = this.doctor_awardsAchievemets[index];
      this.is_update = true;
      if (data?.name != "" && data?.name != null) {
        this.awardsForm.name = data.name;
      }
      if (data?.id !== null && data?.id !== "") {
        this.awardsForm.id = data.id;
      }
    },
    editLink(index) {
      this.is_add_link = false;
      this.is_link_modal = true;
      const data = this.doctor_links[index];
      console.log(data, "datat");
      if (data.internal_link == 1) {
        this.post_url = {
          id: data?.id,
          post_title: data?.meta_title,
        };
        this.fetch_url = "";
        this.form6.id = data?.id;
      } else {
        this.post_url = null;
        this.fetch_url = data?.url;
        this.form6.id = data?.id;
      }
    },
    // Alert for link delete
    async deleteLink(id) {
      try {
        let result = await this.showSweetAlert();
        if (result.isConfirmed) {
          // When user cick OK
          this.handleDeleteLinks(id);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    showSweetAlert() {
      return new Promise((resolve) => {
        this.$swal({
          title: "Are you sure?",
          text: "You are about to delete this link.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          dangerMode: true,
        }).then((result) => {
          resolve(result);
        });
      });
    },
    async handleDeleteLinks(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = members.deleteLinks;
        let dataAppend = new FormData();
        dataAppend.append("id", id);
        const data = await this.postRequest(url, dataAppend, {
          _method: "DELETE",
        });
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        if (data) {
          await this.$nextTick();

          this.fetchLink(this.$route.params.id);
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    addPublication() {
      this.is_add_publication = true;
      this.is_publication_modal = true;
      this.form5.member_publication_ref_no = "";
      this.form5.title = "";
      this.form5.link = "";
      this.form5.published_on = "";
      this.form5.summery = "";
    },
    addLink() {
      this.is_add_link = true;
      this.is_link_modal = true;
      this.form6.url = "";
      this.form6.meta_title = "";
      this.form6.meta_desc = "";
      this.form6.meta_image = "";
    },
    changePostUrl() {
      this.form6.meta_title = "";
      this.form6.meta_desc = "";
      this.form6.meta_image = "";
      this.form6.url = "";
      this.removeItem = null;
      this.post_url = null;
    },
    addAwardsAchievements() {
      this.is_add_award = true;
      this.awardModel = true;
      this.awardsForm.name = "";
    },
    removeQueryParam() {
      let uri = window.location.toString();
      if (uri.indexOf("?") > 0) {
        let clean_uri = uri.substring(0, uri.indexOf("?"));
        window.history.replaceState({}, document.title, clean_uri);
      }
    },
    // checkData() {
    //   const keys = Object.keys(localStorage).filter((item) =>
    //     item.includes("cacheData_")
    //   );
    //   keys.forEach(function (key) {
    //     console.log(key, "foreach individual key");
    //     const value = localStorage.getItem(key);
    //     const parsedValue = JSON.parse(value);
    //     const currentDate = new Date();
    //     const currentTimestamp = currentDate?.getTime();
    //     const expiredTimestamp = parsedValue?.expiry;
    //     if (expiredTimestamp && currentTimestamp >= expiredTimestamp) {
    //       console.log(expiredTimestamp, currentTimestamp);
    //       localStorage.removeItem(key);
    //     }
    //   });
    // },
    // removeData(isRemove) {
    //   const key = Object.keys(localStorage).filter((item) =>
    //     item.includes("cacheData_")
    //   );
    //   key.forEach(function (key) {
    //     localStorage.removeItem(key);
    //   });
    //   if (isRemove) {
    //     this.currentPage = 1;
    //     this.filter = "";
    //     this.fetchData();
    //   }
    // },
    searchFor() {
      if (this.filter.length > 2) {
        // this.removeData();
        this.currentPage = 1;
        this.fetchData();
      } else if (this.filter.length == 0) {
        this.currentPage = 1;
        this.fetchData();
      }
    },
    search(event) {
      if (this.filter.length > 2) {
        // this.removeData();
        if (event.keyCode == 13) {
          this.currentPage = 1;
          this.fetchData();
        }
      } else if (this.filter.length == 0) {
        this.currentPage = 1;
        this.removeQueryParam();
        this.fetchData();
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData() {
      // const checkCache = this.cacheData(this.currentPage);
      // if (!checkCache) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = members.getMembers;
        url = `${url}?search=${this.filter ?? ""}`;
        this.getUrl();
        url += this.params;
        const data = await this.getRequest(url);
        if (data.status) {
          this.member_list = data.response;
          // const currentTime = new Date();
          // const item = {
          //   value: this.member_list,
          //   expiry: currentTime.getTime() + 30 * 60 * 1000,
          // };
          // if (!this.filter) {
          //   localStorage.setItem(
          //     "cacheData_" + this.currentPage,
          //     JSON.stringify(item)
          //   );
          // }
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
      // }
    },
    cacheData(page) {
      const storedData = localStorage.getItem("cacheData_" + page);
      if (storedData) {
        const jsonRes = JSON.parse(storedData);
        this.member_list = jsonRes?.value;
        return true;
      }
      return false;
    },
    getUrl() {
      let url = new URL(window.location);
      if (url.pathname.includes("edit-member")) {
        url.searchParams.delete("filter");
        url.searchParams.delete("page", 1);
      } else {
        if (this.filter != null && this.filter != "") {
          url.searchParams.has("search")
            ? url.searchParams.set("search", this.filter)
            : url.searchParams.append("search", this.filter);
        } else {
          url.searchParams.delete("search");
        }
        if (this.currentPage != null && this.currentPage != "") {
          url.searchParams.has("page")
            ? url.searchParams.set("page", this.currentPage)
            : url.searchParams.append("page", this.currentPage);
        } else {
          url.searchParams.set("page", 1);
        }
        url.search = url.searchParams;
        url = url.toString();
        history.pushState({}, null, url);
      }
    },
    async fetchMemberTag() {
      this.$store.commit("loader/updateStatus", true);
      try {
        const url = member_tag.getMemberTagData;
        const data = await this.getRequest(url);
        if (data.status) {
          this.getMemberTag = data.response.data;
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchWorkplace(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const url = members.getworkplace + "/" + id;
        const data = await this.getRequest(url);
        if (data.status) {
          this.doctor_work_place = data.response;
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchAcademics(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const url = members.getDoctorAcademics + "/" + id;
        const data = await this.getRequest(url);
        if (data.status) {
          this.doctor_academics = data.response;
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchPublication(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const url = members.getPublication + "/" + id;
        const data = await this.getRequest(url);
        if (data.status) {
          this.doctor_publications = data.response;
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchAwardsAchievements(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const url = members.getAwardsAchievements + "/" + id;
        const data = await this.getRequest(url);
        if (data.status) {
          this.doctor_awardsAchievemets = data.response;
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchLink(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const url = members.getLink + "/" + id;
        const data = await this.getRequest(url);
        if (data.status) {
          this.doctor_links = data.response;
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchQualification() {
      try {
        const url =
          members.getQualification + "/" + this.form1.speciality_id.id;
        const data = await this.getRequest(url);
        if (data.status) {
          this.qualification = data.response;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchInstitute() {
      try {
        const url = members.getInstitute;
        const data = await this.getRequest(url);
        if (data.status) {
          this.institute = data.response;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchWorkplaceList() {
      try {
        const url = members.getworkplaceList;
        const data = await this.getRequest(url);
        if (data.status) {
          this.workplacelist = data.response;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async updateData() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = members.updateMember + "/" + this.modalItem.id;
        let dataAppend = new FormData();
        dataAppend.append("member_tag_type_id", this.form.member_tag_type_id);
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async updateWorkplace() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = members.updateWorkplace;
        let dataAppend = new FormData();
        dataAppend.append(
          "member_workplace_info_ref_no",
          this.form2.member_workplace_info_ref_no ?? ""
        );
        dataAppend.append("id", this.$route.params.id);
        dataAppend.append("work_place_type", this.form2.work_place_type);
        dataAppend.append("designation", this.form2.designation);
        dataAppend.append("place_of_work", this.form2.place_of_work);
        dataAppend.append("country", this.form2?.country?.name);
        dataAppend.append("state", this.form2?.state?.state);
        dataAppend.append("city", this.form2?.city?.city);
        dataAppend.append("start_date", this.form2.start_date);
        dataAppend.append("end_date", this.form2.end_date);
        dataAppend.append("currently_working", this.form2.currently_working);
        dataAppend.append("address", this.form2.address);
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        if (data) {
          await this.$nextTick();
          this.fetchWorkplace(this.$route.params.id);
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async updateStatusWorkPlace(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const index = this.doctor_work_place.findIndex(
          (element) => element.id == id
        );
        console.log(index);
        const url = `${members.updateWorkplaceStatus}/${id}`;
        const data = await this.postRequest(url, {
          is_active: !this.doctor_work_place[index].is_active,
        });
        if (data.status) {
          this.doctor_work_place[index].is_active =
            !this.doctor_work_place[index].is_active;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "Status updated successfully",
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async deleteWorkPlace(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const index = this.doctor_work_place.findIndex((e) => e.id == id);
        const url = `${members.deleteWorkPlace}/${id}`;
        const res = await this.postRequest(url, {
          _method: "delete",
        });
        if (res.status) {
          this.doctor_work_place.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: res.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    updateInstituteRefNo() {
      this.form4.other_institute_name = "";
      const selectedInstitute = this.form4.institute_name.institute_name;
      const matchingInstitute = this.institute.find(
        (item) => item.institute_name === selectedInstitute
      );
      if (matchingInstitute) {
        this.form4.institute_master_ref_no =
          matchingInstitute.institute_master_ref_no;
        console.log(this.form4.institute_master_ref_no, "inst. master");
      } else {
        this.form4.institute_master_ref_no = ""; // Reset if no matching institute found
      }
    },
    updateQulificationRefNo() {
      this.form4.other_course_name = "";
      const selectedQualification = this.form4.course_name.course_name;
      const matchingQualification = this.qualification.find(
        (item) => item.course_name === selectedQualification
      );
      if (matchingQualification) {
        this.form4.qualification =
          matchingQualification?.qualification_master_ref_no;
        this.form4.qualifiation_detail_master_ref_no =
          matchingQualification?.qualification_detail_master_ref_no;
      } else {
        this.form4.qualification = ""; // Reset if no matching institute found
        this.form4.qualifiation_detail_master_ref_no = "";
      }
    },
    async updateAcademic() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = members.updateAcademics;
        let dataAppend = new FormData();
        dataAppend.append(
          "member_academic_info_ref_no",
          this.form4.member_academic_info_ref_no ?? ""
        );
        dataAppend.append("member_id", this.$route.params.id);
        dataAppend.append("start_date", this.form4.start_date);
        dataAppend.append("end_date", this.form4.end_date);
        dataAppend.append("currently_pursuing", this.form4.currently_pursuing);
        if (this.form4.institute_name.institute_name === "Other") {
          dataAppend.append(
            "other_institute_name",
            this.form4?.other_institute_name
          );
          dataAppend.append(
            "institute_master_ref_no",
            this.form4.institute_master_ref_no
          );
        } else {
          console.log("inside inst. name", this.form4.institute_master_ref_no);
          dataAppend.append("other_institute_name", "");
          dataAppend.append(
            "institute_master_ref_no",
            this.form4.institute_master_ref_no
          );
        }
        if (this.form4.course_name.course_name === "Others") {
          dataAppend.append("other_course_name", this.form4?.other_course_name);
          dataAppend.append("qualification", this.form4?.qualification);
        } else {
          dataAppend.append("other_course_name", "");
          dataAppend.append("qualification", this.form4?.qualification);
        }
        if (this.form4?.qualifiation_detail_master_ref_no) {
          dataAppend.append(
            "qualification_detail_master_ref_no",
            this.form4?.qualifiation_detail_master_ref_no
          );
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        if (data) {
          await this.$nextTick();
          this.fetchAcademics(this.$route.params.id);
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async updateStatusAcademics(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const index = this.doctor_academics.findIndex(
          (element) => element.id == id
        );
        console.log(index);
        const url = `${members.updateAcademicsStatus}/${id}`;
        const data = await this.postRequest(url, {
          is_active: !this.doctor_academics[index].is_active,
        });
        if (data.status) {
          this.doctor_academics[index].is_active =
            !this.doctor_academics[index].is_active;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "Status updated successfully",
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async deleteAcademics(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const index = this.doctor_academics.findIndex((e) => e.id == id);
        const url = `${members.deleteAcademics}/${id}`;
        const res = await this.postRequest(url, {
          _method: "delete",
        });
        if (res.status) {
          this.doctor_academics.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: res.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async updatePublication() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = members.updatePublications;
        let dataAppend = new FormData();
        dataAppend.append(
          "member_publication_ref_no",
          this.form5.member_publication_ref_no ?? ""
        );
        dataAppend.append("member_id", this.$route.params.id);
        dataAppend.append("title", this.form5.title);
        dataAppend.append("link", this.form5.link);
        dataAppend.append("published_on", this.form5.published_on);
        dataAppend.append("summery", this.form5.summery);
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        if (data) {
          await this.$nextTick();
          this.fetchPublication(this.$route.params.id);
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async updateStatusPublication(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const index = this.doctor_publications.findIndex(
          (element) => element.id == id
        );
        console.log(index);
        const url = `${members.updatePublicationStatus}/${id}`;
        const data = await this.postRequest(url, {
          is_active: !this.doctor_publications[index].is_active,
        });
        if (data.status) {
          this.doctor_publications[index].is_active =
            !this.doctor_publications[index].is_active;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "Status updated successfully",
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async deletePublication(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const index = this.doctor_publications.findIndex(
          (element) => element.id == id
        );
        let url = `${members.deletePublication}/${id}`;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.doctor_publications.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "data deleted sucessfully.",
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async updateAwardAchievements() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url;
        if (this.is_update == true) {
          url = members.getAwardsAchievements + `/${this.awardsForm.id}`;
        } else {
          url = members.getAwardsAchievements;
        }
        let dataAppend = new FormData();
        dataAppend.append("member_id", this.$route.params.id);
        dataAppend.append("name", this.awardsForm.name);
        if (this.is_update == true) {
          dataAppend.append("_method", "put");
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        if (data) {
          this.is_update = false;
          await this.$nextTick();
          this.fetchAwardsAchievements(this.$route.params.id);
        }
      } catch (err) {
        this.is_update = false;
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async updateStatusAwardAchievements(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const index = this.doctor_awardsAchievemets.findIndex(
          (element) => element.id == id
        );
        const url = `${members.updateAwardStatus}/${id}`;
        const data = await this.postRequest(url, {
          is_active: !this.doctor_awardsAchievemets[index].is_active,
        });
        if (data.status) {
          this.doctor_awardsAchievemets[index].is_active =
            !this.doctor_awardsAchievemets[index].is_active;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "Status updated successfully",
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async deleteAwardAchievements(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const index = this.doctor_awardsAchievemets.findIndex(
          (element) => element.id == id
        );
        let url = `${members.getAwardsAchievements}/${id}`;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.doctor_awardsAchievemets.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "data deleted sucessfully.",
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async updateLink() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = members.updateLinks;
        let dataAppend = new FormData();
        dataAppend.append("member_id", this.$route.params.id);
        dataAppend.append("meta_title", this.form6.meta_title ?? "");
        dataAppend.append("id", this.form6.id ?? "");
        dataAppend.append("meta_desc", this.form6.meta_desc);
        dataAppend.append("meta_image", this.form6.meta_image);
        dataAppend.append(
          "url",
          this.fetch_url ? this.fetch_url : this.form6.url ? this.form6.url : ""
        );
        if (this.fetch_url == "") {
          dataAppend.append("type", this.post_url ? "medisage" : "");
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        if (data) {
          await this.$nextTick();
          this.fetchLink(this.$route.params.id);
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async checkPassword() {
      try {
        this.$store.commit("loader/updateStatus", true);
        let url = members.checkPassword;
        let dataAppend = new FormData();
        dataAppend.append("id", this.modalPassword);
        dataAppend.append("password", this.form3.password);
        const data = await this.postRequest(url, dataAppend);
        this.passwordMatch = data.response.status;
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        if (data.response) {
          this.navigateToSuccessPage(this.modalPassword);
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    navigateToSuccessPage(id) {
      this.$router.push({ name: "edit-member", params: { id } });
    },
    openModal(itemId) {
      this.fetchMemberTag();
      this.modalItem = itemId;
      this.showModal = true;
      if (this.modalItem.fname != null || this.modalItem.lname != null) {
        this.form.member_name =
          this.modalItem.fname + " " + this.modalItem.lname;
      } else {
        this.form.member_name = "---";
      }
      if (this.modalItem.email != null && this.modalItem.email != "") {
        this.form.email = this.modalItem.email;
      } else {
        this.form.email = "---";
      }
      if (
        this.modalItem.mobile_number != null &&
        this.modalItem.mobile_number != ""
      ) {
        this.form.mobile_number = this.modalItem.mobile_number;
      } else {
        this.form.mobile_number = "---";
      }
      if (
        this.modalItem.member_tag_type_id != null &&
        this.modalItem.member_tag_type_id != ""
      ) {
        this.form.member_tag_type_id = this.modalItem.member_tag_type_id;
      } else {
        this.form.member_tag_type_id = "";
      }
    },
    passwordModal(itemId) {
      this.modalPassword = itemId;
      this.showPasswordModal = true;
      this.form3.password = "";
    },
    closeModal() {
      this.is_update = false;
      this.submitted = false;
    },
    checkFormValidity() {
      if (this.form.member_tag_type_id == "") {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields",
        });
        return false;
      }
      return true;
    },
    checkFormPasswordValidity() {
      if (this.form3.password == "") {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Password Field",
        });
        return false;
      } else if (this.passwordMatch == false) {
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: this.passwordMatch,
          icon: "error",
          title: "Please Fill Correct pasword",
        });
      }
      return true;
    },
    checkFormWorkplaceValidity() {
      if (this.form2.designation == "") {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Designation Field Required",
        });
        return false;
      }
      if (this.form2.place_of_work == "") {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Place Of Work Field Required",
        });
        return false;
      }
      if (this.form2.work_place_type == "") {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Workplace Field Required",
        });
        return false;
      }
      if (this.form2.country == "") {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Country Field Required",
        });
        return false;
      }
      if (this.form2.state == "") {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "State Field Required",
        });
        return false;
      }
      if (this.form2.city == "") {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "City Field Required",
        });
        return false;
      }
      return true;
    },
    checkFormAcademicValidity() {
      if (this.form4.course_name == "") {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Qualification Field Required",
        });
        return false;
      }
      if (
        this.form4.course_name == "Others" &&
        this.form4.other_course_name == ""
      ) {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Other Course Name Field Required",
        });
        return false;
      }
      if (this.form4.institute_name == "") {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Institute Field Required",
        });
        return false;
      }
      if (
        this.form4.institute_name == "Other" &&
        this.form4.other_institute_name == ""
      ) {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Other Institute Name Field Required",
        });
        return false;
      }
      return true;
    },
    checkFormAwardsValidity() {
      if (this.awardsForm.name == "") {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Name Filed Required",
        });
        return false;
      }
      return true;
    },
    checkFormPublicationValidity() {
      if (this.form5.title == "") {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Title Field Required",
        });
        return false;
      }
      if (this.form5.link == "") {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Link Field Required",
        });
        return false;
      }
      if (this.form5.published_on == "") {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Publish On Field Required",
        });
        return false;
      }
      if (this.form5.summery == "") {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Summery Field Required",
        });
        return false;
      }
      return true;
    },
    checkFormLinkValidity() {
      // if (
      //   (this.post_url == null && this.fetch_url !== "") ||
      //   (this.post_url !== null && this.fetch_url == "")
      // ) {
      //   this.submitted = true;
      //   this.$v.$touch();
      //   this.$store.commit("toast/updateStatus", {
      //     status: true,
      //     icon: "error",
      //     title: "Fetch any one URL",
      //   });
      //   return false;
      // }
      if (this.form6.meta_title == "") {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please fetch URL first",
        });
        return false;
      }
      return true;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    handleWorkplaces(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmitWorkplaces();
    },
    handleAcademics(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmitAcademics();
    },
    handlePublications(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmitPublications();
    },
    handleAwardAchievements(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmitAwards();
    },
    handleLinks(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmitLinks();
    },
    handlePassword(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmitPassword();
    },
    handleSubmitAwards() {
      let validateForm = this.checkFormAwardsValidity();
      if (!validateForm) {
        return;
      } else {
        this.updateAwardAchievements();
        this.$nextTick(() => {
          this.$bvModal.hide("bvModal");
        });
        this.submitted = false;
        this.fetchData(this.currentPage);
      }
    },
    handleSubmit() {
      let validateForm = this.checkFormValidity();
      if (!validateForm) {
        return;
      } else {
        this.updateData();
        this.$nextTick(() => {
          this.$bvModal.hide("bvModal");
        });
        this.submitted = false;
        this.fetchData(this.currentPage);
      }
    },
    handleSubmitWorkplaces() {
      let validateForm = this.checkFormWorkplaceValidity();
      if (!validateForm) {
        return;
      } else {
        this.updateWorkplace();
        this.$nextTick(() => {
          this.$bvModal.hide("bvModal");
        });
        this.submitted = false;
        this.fetchData(this.currentPage);
      }
    },
    handleSubmitAcademics() {
      let validateForm = this.checkFormAcademicValidity();
      if (!validateForm) {
        return;
      } else {
        this.updateAcademic();
        this.$nextTick(() => {
          this.$bvModal.hide("bvModal");
        });
        this.submitted = false;
        this.fetchData(this.currentPage);
      }
    },
    handleSubmitPublications() {
      let validateForm = this.checkFormPublicationValidity();
      if (!validateForm) {
        return;
      } else {
        this.updatePublication();
        this.$nextTick(() => {
          this.$bvModal.hide("bvModal");
        });
        this.submitted = false;
        this.fetchData(this.currentPage);
      }
    },
    handleSubmitLinks() {
      let validateForm = this.checkFormLinkValidity();
      if (!validateForm) {
        return;
      } else {
        this.updateLink();
        this.$nextTick(() => {
          this.$bvModal.hide("bvModal");
        });
        this.submitted = false;
        this.fetchData(this.currentPage);
      }
    },
    handleSubmitPassword() {
      let validateForm = this.checkFormPasswordValidity();
      if (!validateForm) {
        return;
      } else {
        this.checkPassword();
        this.$nextTick(() => {
          this.$bvModal.hide("bvModal");
        });
        this.submitted = false;
        this.fetchData(this.currentPage);
      }
    },
    async fetchSingleSpeciality(id) {
      const url = speciality.specialityUrl;
      const data = await this.getRequest(url + "/" + id);
      if (data.status) {
        this.form1.speciality_id = data.response;
      }
    },
    async fetchSlider(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = members.getMembers + "/" + id;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.member_data = data.response;
          this.member_id = responseData.id;
          if (responseData?.is_lead && responseData?.is_lead?.lead_id) {
            this.form1.lead_id = responseData?.is_lead?.lead_id;
          }
          if (responseData?.short_url) {
            this.form1.short_url = responseData?.short_url;
          }
          if (responseData.fname != "" && responseData.fname != null) {
            this.form1.fname = responseData.fname;
          }
          if (responseData.lname != "" && responseData.lname != null) {
            this.form1.lname = responseData.lname;
          }
          if (responseData.dob != "" && responseData.dob != null) {
            this.form1.dob = responseData.dob;
          }
          if (
            responseData.speciality_id != null &&
            responseData.speciality_id != ""
          ) {
            this.form1.speciality_id = this.fetchSingleSpeciality(
              responseData?.speciality_id
            );
            this.fetchQualification();
          }
          if (
            responseData.years_of_experience != null &&
            responseData.years_of_experience != ""
          ) {
            this.form1.years_of_experience = responseData.years_of_experience;
          }

          if (responseData.email != null && responseData.email != "") {
            this.form1.email = responseData.email;
          }
          if (
            responseData.mobile_number != null &&
            responseData.mobile_number != ""
          ) {
            this.form1.mobile_number = responseData.mobile_number;
          }
          if (responseData.country_code) {
            this.form1.country_code = responseData.country_code;
          }

          if (responseData.pincode != null && responseData.pincode != "") {
            this.form1.pincode = responseData.pincode;
          }
          if (responseData.address != null && responseData.address != "") {
            this.form1.address = responseData.address;
          }
          if (responseData.country != null && responseData.country != "") {
            this.form1.country = {
              name: responseData.country,
            };
          }
          if (responseData.state != null && responseData.state != "") {
            this.form1.state = {
              state: responseData.state,
            };
          }
          if (responseData.city != null && responseData.city != "") {
            this.form1.city = {
              city: responseData.city,
            };
          }
          if (
            responseData.member_type != null &&
            responseData.member_type != ""
          ) {
            this.form1.member_type = responseData.member_type;
          }
          if (responseData.is_verified_with_mci) {
            this.form1.is_verified_with_mci = responseData.is_verified_with_mci;
          }

          if (responseData.mci_registration_no) {
            this.form1.mci_registration_no = responseData.mci_registration_no;
          }

          if (responseData.mci_registration_state) {
            this.form1.mci_registration_state = {
              state: responseData.mci_registration_state,
            };
          }
          if (responseData.mci_registration_year) {
            const date = new Date(responseData.mci_registration_year);
            this.form1.mci_registration_year = date;
          }

          if (responseData.last_active_date) {
            this.form1.last_active_date = responseData.last_active_date;
          }

          if (responseData.whatsapp_status) {
            this.form1.whatsapp_status = responseData.whatsapp_status;
          }

          if (responseData.sms_status) {
            this.form1.sms_status = responseData.sms_status;
          }
          if (responseData.alternate_number) {
            this.form1.alternate_number = responseData.alternate_number;
          }
          if (responseData.whatsapp_number) {
            this.form1.whatsapp_number = responseData.whatsapp_number;
          }
          if (responseData.professional_bio) {
            this.form1.professional_bio = responseData.professional_bio;
          }
          if (responseData.professional_headline) {
            this.form1.professional_headline =
              responseData.professional_headline;
          }
          if (
            responseData.interest_area != null &&
            responseData.interest_area != ""
          ) {
            this.form1.interest_areas = [];
            responseData.interest_area.map((item) => {
              this.fetchSingleInterestArea(item.id);
            });
          }
          if (
            responseData.member_extra_details?.meta_title != null &&
            responseData.member_extra_details?.meta_title != ""
          ) {
            this.form1.meta_title =
              responseData.member_extra_details.meta_title;
          }
          if (
            responseData.member_extra_details?.meta_description != null &&
            responseData.member_extra_details?.meta_description !== ""
          ) {
            this.form1.meta_description =
              responseData.member_extra_details.meta_description;
          }
          if (
            responseData.member_extra_details?.meta_keywords != null &&
            responseData.member_extra_details?.meta_keywords != ""
          ) {
            this.form1.meta_keywords =
              responseData.member_extra_details.meta_keywords;
          }
          if (
            responseData.member_extra_details?.theme_color != null &&
            responseData.member_extra_details?.theme_color != ""
          ) {
            this.form1.theme_color =
              responseData.member_extra_details.theme_color;
          }
          if (
            responseData.member_extra_details?.website_link != null &&
            responseData.member_extra_details?.website_link != ""
          ) {
            this.form1.website_link =
              responseData.member_extra_details.website_link;
          }
          if (responseData.profile_image)
            this.form1.profile_image_url = responseData.profile_image;
          this.form1.cover_image_url = responseData.cover_image;
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchInterestArea(query) {
      const url = community.fetchAllActiveCommunity;
      const data = await this.getRequest(url + "?search=" + query);
      if (data.status) {
        this.interest = data.response.data;
      }
    },
    async fetchMedisageBlog(query) {
      const url = medisage_blog.getMediBlogPostUrl;
      const data = await this.getRequest(url + "?search=" + query);
      if (data.status) {
        this.medisageBlogList = data.response.data;
      }
    },

    async fetchSingleInterestArea(name) {
      const url = community.fetchCommunity;
      const data = await this.getRequest(url + "/" + name);
      if (data.status) {
        this.form1.interest_areas.push(data.response);
      }
    },
    async fetchCountry(query) {
      const url = country.countryUrl;
      const data = await this.getRequest(url + "?search=" + query);
      if (data.status) {
        this.allCountry = data.response.data;
      }
    },
    fetchSpeciality: _.debounce(async function (name) {
      this.$store.commit("loader/updateStatus", true);
      try {
        if (name != null && name != "") {
          const url = speciality.specialityUrl;
          const data = await this.getRequest(`${url}?search=${name}`);
          if (data.status) {
            this.speciality = data.response.data;
          }
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Something went wrong",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    }, 1000),
    async fetchAllCountry() {
      const url = country.fetchAllCountry;
      const data = await this.getRequest(url);
      if (data.status) {
        this.allCountry = data.response.data;
      }
    },

    async fetchStateCouncil() {
      const url = mci_verification.getStateCouncil;
      const data = await this.getRequest(url);
      if (data.status) {
        this.mciState = data.response;
      }
    },

    getStatesByCountry: _.debounce(async function () {
      // this.$store.commit("loader/updateStatus", true);
      // this.form1.state = '';
      // this.form1.city = '';
      this.allState = [];
      const countryName = this.form1.country.name;
      if (countryName != null && countryName != "") {
        const url = stateUrl.state + "/" + countryName;
        const data = await this.getRequest(url);
        if (data.status) {
          this.allState = data.response;
        }
      }
      // this.$store.commit("loader/updateStatus", false);
    }, 1000),

    async getStateWorkPlacesByCountry() {
      console.log("inside state");
      // this.form2.state = '';
      //   this.form2.city = '';
      // this.$store.commit("loader/updateStatus", true);
      // this.allWorkPlaceState = [];
      const countryName = this.form2.country.name;
      console.log(countryName, "countryname");
      if (countryName != null && countryName != "") {
        const url = stateUrl.state + "/" + countryName;
        const data = await this.getRequest(url);
        if (data.status) {
          this.allWorkPlaceState = data.response;
        }
      }
      // this.$store.commit("loader/updateStatus", false);
    },

    getCitiesByState: _.debounce(async function () {
      // this.$store.commit("loader/updateStatus", true);
      // this.form1.city = '';
      this.allCity = [];
      if (this.form1.state != null) {
        const url =
          cityUrl.city +
          "/" +
          this.form1.country +
          "/" +
          this.form1.state.state;
        const data = await this.getRequest(url);
        if (data.status) {
          this.allCity = data.response;
        }
      }
      // this.$store.commit("loader/updateStatus", false);
    }, 1000),

    async getWorkPlaceCitiesByState() {
      console.log("inside work.");
      // this.form2.city = "";
      this.allWorkPlaceCity = [];
      if (this.form2.state) {
        const url =
          cityUrl.city +
          "/" +
          this.form2.country.name +
          "/" +
          this.form2.state.state;
        console.log(this.form2.state.state, this.form2.country);
        const data = await this.getRequest(url);
        if (data.status) {
          this.allWorkPlaceCity = data.response;
        }
      }
    },

    async fetchMetadata() {
      this.$store.commit("loader/updateStatus", true);
      document.getElementById("fetch-url").style.border = "";
      const formattedURL = this.fetch_url;
      console.log(formattedURL, "url");
      try {
        const response = await this.getRequest(
          `${this.$hostLink}/api/metaTags?url=${formattedURL}`
        );
        if ("title" in response.metadata) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "URL fetched successfully",
          });
          const title = response.metadata.title;
          const responseData = response.metadata;
          this.form6.meta_title = responseData.title;
          if (responseData.description) {
            this.form6.meta_desc = responseData.description;
          }
          if (responseData.image) {
            this.form6.meta_image = responseData.image;
          }

          console.log(`Title: ${title}`);
        } else {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Invalid Post URL",
          });
          console.error("Error: Title key is missing in the metadata object.");
        }
      } catch (err) {
        console.log(err, "inside log");
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Invalid Post URL",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    resetStateAndCity() {
      console.log("inside reset");
      this.form1.state = "";
      this.form1.city = "";
      this.allState = [];
      // this.getStatesByCountry();
    },

    resetWorkPlaceStateAndCity() {
      this.form2.state = "";
      this.form2.city = "";
      this.allWorkPlaceState = [];
      this.allWorkPlaceCity = [];
      // this.getStateWorkPlacesByCountry();
    },

    resetCity() {
      this.form1.city = "";
      this.allCity = [];
      // this.getCitiesByState();
    },

    resetWorkPlaceCity() {
      this.form2.city = "";
      this.allWorkPlaceCity = [];
      // this.getWorkPlaceCitiesByState();
    },

    readFile(e, txt) {
      if (txt == "profile_image") {
        this.profile_image = e.target.files[0];
        if (this.profile_image.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.profile_image.reset();
          this.profile_image_url = "";
          this.form1.profile_image_url = "";
          this.profile_image = "";
          return false;
        } else {
          this.profile_image_url = URL.createObjectURL(this.profile_image);
          this.form1.profile_image_url = URL.createObjectURL(
            this.profile_image
          );
        }
        return true;
      }
      if (txt == "cover_image") {
        this.cover_image = e.target.files[0];
        if (this.cover_image.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.cover_image.reset();
          this.cover_image_url = "";
          this.form1.cover_image_url = "";
          this.cover_image = "";
          return false;
        } else {
          this.cover_image_url = URL.createObjectURL(this.cover_image);
          this.form1.cover_image_url = URL.createObjectURL(this.cover_image);
        }
        return true;
      }
    },

    async submitData() {
      let validateForm = this.checkWebsiteLinkValidity();
      if (!validateForm) {
        return;
      } else {
        try {
          let url = members.getMembers;
          if (this.$route.name == "edit-member") {
            url = members.getMembers + "/" + this.member_id;
            let dataAppend = new FormData();
            if (this.form1.fname) {
              dataAppend.append("fname", this.form1.fname);
            }
            if (this.form1.lname) {
              dataAppend.append("lname", this.form1.lname);
            }
            if (this.form1.dob) {
              dataAppend.append("dob", this.form1.dob);
            }
            if (this.form1.speciality) {
              dataAppend.append("speciality_id", this.form1.speciality_id);
            }
            if (this?.form1?.city?.city) {
              dataAppend.append("city", this?.form1?.city?.city);
            }
            if (this?.form1?.state?.state) {
              dataAppend.append("state", this?.form1?.state?.state);
            }
            if (this?.form1?.country) {
              dataAppend.append("country", this?.form1?.country?.name);
            }
            if (this.form1.mobile_number) {
              dataAppend.append("mobile_number", this.form1.mobile_number);
            }
            if (this.form1.pincode) {
              dataAppend.append("pincode", this.form1.pincode);
            }
            if (this.form1.email) {
              dataAppend.append("email", this.form1.email);
            }
            if (this.form1.years_of_experience) {
              dataAppend.append(
                "years_of_experience",
                this.form1.years_of_experience
              );
            }
            if (this.form1.address) {
              dataAppend.append("address", this.form1.address);
            }
            if (this.profile_image) {
              dataAppend.append("profile_image", this.profile_image);
            }
            if (this.professional_headline) {
              dataAppend.append(
                "professional_headline",
                this.professional_headline
              );
            }
            if (this.professional_bio) {
              dataAppend.append("professional_bio", this.professional_bio);
            }
            if (this.cover_image) {
              dataAppend.append("cover_image", this.cover_image);
            }
            if (this.form1.member_type) {
              dataAppend.append("member_type", this.form1.member_type);
            }
            if (this.form1.whatsapp_status) {
              dataAppend.append("whatsapp_status", this.form1.whatsapp_status);
            }
            if (this.form1.sms_status) {
              dataAppend.append("sms_status", this.form1.sms_status);
            }
            if (this.form1.alternate_number) {
              dataAppend.append(
                "alternate_number",
                this.form1.alternate_number
              );
            }
            if (this.form1.whatsapp_number) {
              dataAppend.append("whatsapp_number", this.form1.whatsapp_number);
            }
            if (this.form1.speciality_id) {
              dataAppend.append("speciality_id", this.form1?.speciality_id?.id);
            }
            if (this?.form1?.mci_registration_state?.state) {
              dataAppend.append(
                "mci_registration_state",
                this?.form1?.mci_registration_state?.state
              );
            }
            if (this?.form1?.mci_registration_year) {
              const selectedYear = new Date(
                this.form1.mci_registration_year
              ).getFullYear();
              dataAppend.append("mci_registration_year", selectedYear);
            }
            for (var key in this.form1) {
              if (
                key != "interest_area" &&
                key != "speciality_id" &&
                key != "country" &&
                key != "city" &&
                key != "state" &&
                key != "mci_registration_state" &&
                key != "mci_registration_year" &&
                key != "profile_image"
              ) {
                dataAppend.append(key, this.form1[key]);
              }
            }

            const interest_areas = [];
            this.form1.interest_areas.map((key) => {
              interest_areas.push(key.id);
            });

            dataAppend.append("interest_area", JSON.stringify(interest_areas));

            if (this.$route.name == "edit-member") {
              dataAppend.append("_method", "put");
            }

            const data = await this.postRequest(url, dataAppend);
            if (data.status) {
              this.$store.commit("toast/updateStatus", {
                status: true,
                icon: "success",
                title: data.message,
              });
            }
          }
          this.fetchSlider(this.$route.params.id);
        } catch (err) {
          console.log(err);
        }
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async toggleReviewStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.member_list.data.findIndex((e) => e.id === id);
        const url = `${members.updateReviewStatus}/${id}`;
        const data = await this.postRequest(url, {
          is_review: !this.member_list.data[index].is_review,
          // id: id,
        });
        if (data.status) {
          this.member_list.data[index].is_review =
            !this.member_list.data[index].is_review;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async updateStatus(id) {
      try {
        let index = this.member_list.data.findIndex((e) => e.id === id);
        if (!this.member_list.data[index].demo_number == "1") {
          let res = await this.$bvModal.msgBoxConfirm(
            "Are you sure you want to set the Internal User?",
            {
              title: "Confirmation",
              size: "sm",
              buttonSize: "sm",
              okVariant: "success",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            }
          );
          if (res == false) {
            let index = this.member_list.data.findIndex((e) => e.id === id);
            console.log(index != -1);
            if (index != -1) {
              this.member_list.data[index].demo_number = this.demo_number;
              this.fetchData();
            }
          } else {
            const url = members.statusUpdate + "/" + id;
            const data = await this.postRequest(url, {
              demo_number: !this.member_list.data[index].demo_number,
            });
            if (data.status) {
              const responseData = data.response;
              this.member_list.data[index] = responseData;
              this.$store.commit("toast/updateStatus", {
                status: true,
                icon: "success",
                title: data.message,
              });
            }
            this.key += 1;
            this.fetchData();
          }
        } else {
          let res = await this.$bvModal.msgBoxConfirm(
            "Are you sure you want to remove the Internal User?",
            {
              title: "Confirmation",
              size: "sm",
              buttonSize: "sm",
              okVariant: "success",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            }
          );
          if (res == false) {
            let index = this.member_list.data.findIndex((f) => f.id == id);
            console.log(index != -1);
            if (index != -1) {
              this.member_list.data[index].demo_number = this.demo_number;
              this.fetchData();
            }
          } else {
            const url = members.statusUpdate + "/" + id;
            const data = await this.postRequest(url, {
              demo_number: !this.member_list.data[index].demo_number,
            });
            if (data.status) {
              const responseData = data.response;
              this.member_list.data[index] = responseData;
              this.$store.commit("toast/updateStatus", {
                status: true,
                icon: "success",
                title: data.message,
              });
            }
            this.key += 1;
            this.fetchData();
          }
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
    },

    async updateUserStatus(id) {
      try {
        let index = this.member_list.data.findIndex((e) => e.id === id);
        if (!this.member_list.data[index].ban_user == "1") {
          let res = await this.$bvModal.msgBoxConfirm(
            "Are you sure you want to ban User?",
            {
              title: "Confirmation",
              size: "sm",
              buttonSize: "sm",
              okVariant: "success",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            }
          );
          if (res == false) {
            let index = this.member_list.data.findIndex((e) => e.id === id);
            if (index != -1) {
              this.member_list.data[index].ban_user = this.ban_user;
              this.fetchData();
            }
          } else {
            const url = members.updateUserStatus + "/" + id;
            const data = await this.postRequest(url, {
              ban_user: !this.member_list.data[index].ban_user,
            });
            if (data.status) {
              const responseData = data.response;
              this.member_list.data[index] = responseData;
              this.$store.commit("toast/updateStatus", {
                status: true,
                icon: "success",
                title: data.message,
              });
            }
            this.key += 1;
            this.fetchData();
          }
        } else {
          let res = await this.$bvModal.msgBoxConfirm(
            "Are you sure you want to unban User?",
            {
              title: "Confirmation",
              size: "sm",
              buttonSize: "sm",
              okVariant: "success",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            }
          );
          if (res == false) {
            let index = this.member_list.data.findIndex((f) => f.id == id);
            console.log(index != -1);
            if (index != -1) {
              this.member_list.data[index].ban_user = this.ban_user;
              this.fetchData();
            }
          } else {
            const url = members.updateUserStatus + "/" + id;
            const data = await this.postRequest(url, {
              ban_user: !this.member_list.data[index].ban_user,
            });
            if (data.status) {
              const responseData = data.response;
              this.member_list.data[index] = responseData;
              this.$store.commit("toast/updateStatus", {
                status: true,
                icon: "success",
                title: data.message,
              });
            }
            this.key += 1;
            this.fetchData();
          }
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
    },

    async deleteProfile(id) {
      try {
        let res = await this.$bvModal.msgBoxConfirm(
          "Are you sure you want to delete User?",
          {
            title: "Confirmation",
            size: "sm",
            buttonSize: "sm",
            okVariant: "success",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true,
          }
        );
        if (res == false) {
          this.fetchData();
        } else {
          const url = members.deleteProfile + "/" + id;
          const data = await this.postRequest(url, {});
          if (data.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
          this.key += 1;
          this.fetchData();
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
    },
  },
  watch: {
    post_url(v) {
      console.log(v);
    },
    currentPage: {
      handler: function (v) {
        if (v != "" && v != null) {
          this.params = `&page=${v}`;
          this.fetchData(v);
        }
      },
    },
    filter(v) {
      if (v.length == 0) {
        this.removeData();
      }
    },
  },
  created() {
    if (this.$route.name == "member-list") {
      if (this.$route.query?.contactNumber) {
        this.filter = this.$route.query?.contactNumber;
      } else if (this.$route?.query?.search) {
        this.filter = this.$route?.query?.search;
      }
      if (this.$route?.query?.page && this.$route?.query?.page != 1) {
        this.currentPage = parseInt(this.$route?.query?.page);
      } else {
        this.currentPage = 1;
      }
    }
    if (this.$route.name == "edit-member") {
      this.fetchSlider(this.$route.params.id);
      this.fetchAllCountry();
      this.fetchStateCouncil();
      this.fetchWorkplace(this.$route.params.id);
      this.fetchAcademics(this.$route.params.id);
      this.fetchPublication(this.$route.params.id);
      this.fetchLink(this.$route.params.id);
      this.fetchInstitute();
      this.fetchWorkplaceList();
      this.fetchAwardsAchievements(this.$route.params.id);
    }
  },
};
